import React, { ReactElement, ReactNode } from "react";
import { Card } from "antd";
import { css } from "@emotion/css";
import { Svg } from "../../common/Svg";
import { JsxElement } from "typescript";

const cardStyle = css`
  border-radius: 15px;
`;

type Props = {
  cardTitle: ReactElement;
  amount: string;
  children?: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * ロマン残高を表示するコンポーネント
 */
const TotalAmountCard: React.FC<Props> = ({
  cardTitle,
  amount,
  children,
  ...rest
}) => {
  const { Suffix } = Svg;
  return (
    <Card className={cardStyle} {...rest}>
      <div className="text-lg">{cardTitle}</div>
      <div className="text-right">
        <span className="font-roboto text-5xl">{amount}</span>
        <span className="text-base ml-1">
          <Suffix width="17px" height="20px" />
        </span>
      </div>
      {children !== undefined && children}
    </Card>
  );
};
export default TotalAmountCard;
