import React, { FC, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import client from "../core/axios";
import { User } from "../models/user";

export type AuthValue = {
  cognitoSession: CognitoUserSession | null;
  loading: boolean;
  appUser: User | null;
  cognitoUser: any; // TODO
  signUp: (username: string, password: string) => void;
  signIn: (username: string, password: string) => void;
  signOut: () => void;
  updateAuthResult: () => void;
  changePassword: (oldPassword: string, password: string) => any;
};

export const AuthContext = React.createContext<AuthValue>({
  cognitoSession: null,
  cognitoUser: null,
  appUser: null,
  loading: false,
  signUp: () => {},
  signIn: () => {},
  signOut: () => {},
  updateAuthResult: () => {},
  changePassword: () => {},
});

type AuthProviderProps = {
  children: React.ReactNode;
};

type AuthResult = {
  cognitoSession: CognitoUserSession | null;
  cognitoUser: CognitoUser | null;
  appUser: User | null;
};

export const AuthProvider: FC<AuthProviderProps> = ({
  children,
}: AuthProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [authResult, setAuthResult] = useState<AuthResult>({
    cognitoSession: null,
    cognitoUser: null,
    appUser: null,
  });

  const updateAuthResult = async () => {
    const cognitoSession = await Auth.currentSession();
    const cognitoUser = await Auth.currentUserInfo();
    const idToken = cognitoSession.getIdToken().getJwtToken();
    const userId = cognitoUser.username;
    sessionStorage.setItem("idToken", idToken);
    let appUser = null;
    const res = await client.get(`/users?cognitoUserName=${userId}`).catch(() => {
      // 初回ログイン時は取得できないためスルー
    });
    if (res) {
      appUser = res.data && res.data[0];
    }
    setAuthResult({
      cognitoSession,
      cognitoUser,
      appUser, // TODO
    });
  };

  const signUp = async (username: string, password: string) => {
    await Auth.signUp({
      username,
      password,
    });
    await signIn(username, password);
  };

  const signIn = async (username: string, password: string) => {
    await Auth.signIn({
      username,
      password,
    });
    await updateAuthResult();
  };

  const signOut = async () => {
    await Auth.signOut();
    setAuthResult({
      appUser: null,
      cognitoUser: null,
      cognitoSession: null,
    });
  };

  const changePassword = async (oldPassword: string, password: string) => {
    let res = null;
    await Auth.currentAuthenticatedUser().then(async user => {
      await Auth.changePassword(user,oldPassword,password).then(data => {
        updateAuthResult();
      }).catch(e => {
        res = e;
      });
    });
    return res;
  };

  const { appUser, cognitoUser, cognitoSession } = authResult;

  useEffect(() => {
    setLoading(true);
    updateAuthResult().finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        cognitoSession,
        cognitoUser,
        appUser,
        loading,
        signUp,
        signIn,
        signOut,
        updateAuthResult,
        changePassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default {};
