import { Button, notification, Upload } from "antd";
import React, { useState } from "react";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import client from "../../../core/axios";
import UserAvatar from "../UserAvatar";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/auth";
import { customErrorHandler } from "../../../utils/error";

type Props = {
  src?: string | null;
  onSuccess: (filePath: string) => void;
  onDelete: () => void;
  onUploadStart: () => void;
  onFinish: () => void;
};

const UploadImage: React.FC<Props> = ({
  src,
  onSuccess,
  onDelete,
  onFinish,
  onUploadStart,
}) => {
  const [previewSrc, setPreviewSrc] = useState(src || null);
  const { updateAuthResult } = useContext(AuthContext);
  const beforeUpload = async (file: RcFile) => {
    onUploadStart && onUploadStart();
    const params = new FormData();
    setPreviewSrc(URL.createObjectURL(file));
    params.append("file", file);
    try {
      const res = await client.post("/storage/upload", params, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      if (res.data) {
        onSuccess(res.data);
      }
    } catch (err: any) {
      customErrorHandler(err.message);
    } finally {
      onFinish();
    }
  };

  const beforeUploadBase64Image = async (file: RcFile) => {
    onUploadStart();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", async (e: any) => {
      const base64encodedImage = e.target.result;
      setPreviewSrc(base64encodedImage);

      // data:image/png;base64,xxxxxxx.....
      const [head, encodedFile] = base64encodedImage.split(",");
      const mimeType = head.split(";")[0].split(":")[1];
      const params = {
        encodedFile,
        mimeType,
      };
      try {
        const res = await client.post("/storage/uploadByBase64", params);
        if (res.data) {
          onSuccess(res.data);
          updateAuthResult();
        }
      } catch (err: any) {
        customErrorHandler(err.message);
      } finally {
        onFinish();
      }
    });
  };

  return (
    <>
      <div className="relative flex justify-center">
        <div className='relative'>
          <UserAvatar src={previewSrc} />
          {previewSrc && (
            <CloseCircleOutlined 
              className='absolute -top-2 -right-4' size={2}
              onClick={() => {
                setPreviewSrc(null);
                onDelete();
              }}
            />
          )}
        </div>
      </div>
      <div className="text-center mt-2">
        <Upload beforeUpload={beforeUploadBase64Image} showUploadList={false}>
          <Button icon={<UploadOutlined />} ghost shape="round">
            アップロード
          </Button>
        </Upload>
      </div>
    </>
  );
};
export default UploadImage;
