import "./App.less";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router";
import AppLayout from "./layouts/AppLayout";
import Sandbox from "./pages/Sandbox";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import OrderCreate from "./pages/OrderCreate";
import TransactionList from "./pages/TransactionList";
import UserDetail from "./pages/UserDetailNew";
import UserCreate from "./pages/UserCreate";
import UserEdit from "./pages/UserEdit";
import UserList from "./pages/UserListNew";
import RouteAdapter from "./core/routeAdapter";

import { AuthProvider } from "./contexts/auth";
import { QueryParamProvider } from "use-query-params";
import AuthGuard from "./guards/AuthGuard";

import Amplify from "aws-amplify";
import CustomSWRConfig from "./core/CustomSWRConfig";
import { ConfigProvider } from "./contexts/config";
import Debug from "./pages/Debug";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import "./core/amplifyConfig";
import PasswordForget from "./pages/PasswordForget";
import GuestGuard from "./guards/GuestGuard";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ja: require("./lang/ja.json"),
    },
    lng: "ja",
    fallbackLng: "ja",
    interpolation: {
      escapeValue: false,
    },
  });

const App: React.FC = () => {
  return (
    <CustomSWRConfig>
      <AuthProvider>
        <ConfigProvider>
          <Router>
            <QueryParamProvider ReactRouterRoute={RouteAdapter}>
              <Routes>
                <Route path="/" element={<AppLayout />}>
                  <Route path="/" element={<AuthGuard />}>
                    <Route path="" element={<Home />} />
                    <Route path="/debug" element={<Debug />} />
                    <Route path="/order/create" element={<OrderCreate />} />
                    <Route path="/transactions" element={<TransactionList />} />
                    <Route path="/users" element={<UserList />} />
                    <Route path="/users/create" element={<UserCreate />} />
                    <Route path="/users/:id" element={<UserDetail />} />
                    <Route path="/users/:id/edit" element={<UserEdit />} />
                  </Route>
                  <Route element={<GuestGuard />}>
                    <Route path="/sandbox" element={<Sandbox />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route
                      path="/password-forget"
                      element={<PasswordForget />}
                    />
                  </Route>
                </Route>
              </Routes>
            </QueryParamProvider>
          </Router>
        </ConfigProvider>
      </AuthProvider>
    </CustomSWRConfig>
  );
};

export default App;
