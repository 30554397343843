import React, { ReactNode, useState } from "react";
import { Button, Descriptions, List, PageHeader } from "antd";
import useSWR from "swr";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/auth";
import _ from "lodash";
import { Transaction } from "../../../models/transaction";
import AppLoading from "../../common/AppLoading";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import Modal from "antd/lib/modal/Modal";
import ServiceDetailCard from "../../service/ServiceDetailCard";
import { Svg } from "../../common/Svg";
import { css } from "@emotion/css";

const { Suffix } = Svg;

const descriptionStyle = css`
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    font-size: 12px;
  }
`;

type Props = {
  limit?: number;
  showDetail: boolean;
};
const TransactionList: React.FC<Props> = ({ limit, showDetail }) => {
  const { appUser } = useContext(AuthContext);
  const { data } = useSWR(
    appUser ? `/transactions?userId=${appUser.id}` : null
  );
  // TODO サーバーサイドソート
  let transactions = _.sortBy(data, "createdAt").reverse();
  if (limit) {
    transactions = transactions.slice(0, limit);
  }

  const [currentTansaction, setCurrentTransaction] =
    useState<Transaction | null>(null);

  if (data === undefined) {
    return <AppLoading />;
  }

  if (transactions && !transactions.length) {
    return <div className="mb-4 text-center">ロマン取引履歴はありません</div>;
  }

  return (
    <>
      <List
        className="mb-7"
        itemLayout="horizontal"
        dataSource={transactions}
        renderItem={(item: Transaction) => {
          const description = `${dayjs(item.createdAt).format(
            "YYYY.MM.DD HH:mm"
          )}`;

          // 「詳細」ボタンの表示有無
          if (showDetail) {
            return (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    onClick={() => {
                      setCurrentTransaction(item);
                    }}
                  >
                    詳細
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={<TransactionTitle transaction={item} />}
                  description={description}
                />
              </List.Item>
            );
          } else {
            return (
              <List.Item>
                <List.Item.Meta
                  title={<TransactionTitle transaction={item} />}
                  description={description}
                />
              </List.Item>
            );
          }
        }}
      />
      <Modal
        visible={!!currentTansaction}
        onCancel={() => {
          setCurrentTransaction(null);
        }}
        footer={null}
      >
        <h3 className="font-bold">ロマン取引の詳細</h3>
        {currentTansaction && (
          <TransactionTitle transaction={currentTansaction} />
        )}
        {currentTansaction?.order?.service && (
          <>
            <div className="font-bold mt-4 mb-2">交換したサービス</div>
            <ServiceDetailCard service={currentTansaction?.order?.service} />
          </>
        )}

        {currentTansaction?.order?.comment && (
          <>
            <div className="font-bold mt-4 mb-2">コメント</div>
            <div>「{currentTansaction?.order?.comment}」</div>
          </>
        )}
        <div className="font-bold mt-4 mb-2">取引日時</div>
        <div>
          {dayjs(currentTansaction?.createdAt).format("YYYY-MM-DD hh:mm")}
        </div>

        {/* <div className={descriptionStyle}>
          <Descriptions size="small" className="mt-4">
            <Descriptions.Item label="取引ID">
              {currentTansaction?.id}
            </Descriptions.Item>
            <Descriptions.Item label="取引日時">
              {dayjs(currentTansaction?.createdAt).format("YYYY-MM-DD hh:mm")}
            </Descriptions.Item>
          </Descriptions>
        </div> */}
      </Modal>
    </>
  );
};

const TransactionTitle: React.FC<{ transaction: Transaction }> = ({
  transaction,
}) => {
  let title = <></>;
  if (transaction.amount > 0) {
    title = (
      <>
        {transaction?.systemFlag && (
          <span>【ロマン支給】</span>
        )}
        <Link to={`/users/${transaction.targetUser.id}`} className="font-bold">
          {transaction.targetUser.name}
        </Link>
        さんから
        <span className="font-bold">{transaction.amount}</span>
        <span>
          <Suffix width="16px" height="12px" />
        </span>
        をもらいました。
      </>
    );
  } else if (transaction.amount < 0) {
    title = (
      <>
        {transaction?.systemFlag && (
          <span>【ロマン消滅】</span>
        )}
        未交換の
        <span className="font-bold">{Math.abs(transaction.amount)}</span>
        <span>
          <Suffix width="16px" height="12px" />
        </span>
        が消滅しました。
      </>
    );
  }

  return title;
};

export default TransactionList;
