import { Descriptions, Typography } from "antd";
import React from "react";
import AppContainer from "../../components/common/AppContainer";
import AppJsonDump from "../../components/common/AppJsonDump";
import { amplifyConfig } from "../../core/amplifyConfig";

const Environment: React.FC = () => {
  return (
    <div>
      <AppContainer>
        <Descriptions title="環境変数" bordered>
          {Object.keys(process.env).map((key) => {
            const value = process.env[key];
            return (
              <Descriptions.Item label={key} span={24}>
                {value}
              </Descriptions.Item>
            );
          })}
        </Descriptions>

        <Descriptions title="Amplify設定" bordered className="mt-4">
          <Descriptions.Item label="config" span={24}>
            <AppJsonDump json={amplifyConfig} />
          </Descriptions.Item>
        </Descriptions>
      </AppContainer>
    </div>
  );
};

export default Environment;
