import React, { ReactNode } from "react";
import { css } from "@emotion/css";

const containerStyle = css`
  max-width: 750px;
  margin: auto;
  padding: 24px 24px 0;
`;

type Props = React.HTMLAttributes<HTMLDivElement>;

const AppContainer: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <div className={containerStyle} {...rest}>
      {children}
    </div>
  );
};
export default AppContainer;
