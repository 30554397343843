import React, {useContext, useState} from "react";
import {Button, Form, Input, Modal, notification} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {customErrorHandler} from "../../utils/error";
import {AuthContext} from "../../contexts/auth";
import {useTranslation} from "react-i18next";

type Props = {
  modalCtl: any;
};

const UserPasswordChangeModal: React.FC<Props> = ({ modalCtl }) => {
  const [form] = Form.useForm();
  const { changePassword } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onFinish = async (values: {oldPassword: string, password: string}) => {
    try {
      setLoading(true);
      // 認証情報をアップデート
      const res = await changePassword(values.oldPassword, values.password);
      if (res) {
        throw new Error(res?.code);
      }
      notification.success({message: 'パスワードを変更しました。'})
    } catch (err: any) {
      // 辞書ファイルに存在しなかった場合はdefaultValueを利用
      const defaultValue = err.message || 'パスワードが変更できませんでした'
      const translatedMessage = t(`aws.cognito.error.${err.message}`, { defaultValue })
      customErrorHandler(translatedMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
      <Modal title="パスワードの変更" visible={modalCtl.visible} footer={null} onCancel={modalCtl.hide}>
        <>
          <Form layout="vertical" form={form} onFinish={onFinish} className="px-4">
            <Form.Item
                label="変更前のパスワード"
                name="oldPassword"
                rules={[{ required: true, message: "パスワードは必須です" }]}
            >
              <Input.Password
                  placeholder="パスワード(8文字以上・英数字を含む)"
                  iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
              />
            </Form.Item>
            <Form.Item
              label="変更後のパスワード"
              name="password"
              rules={[{ required: true, message: "パスワードは必須です" }]}
            >
              <Input.Password
                placeholder="パスワード(8文字以上・英数字を含む)"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              label="変更後のパスワード（確認用）"
              name="confirm"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: '確認用パスワードは必須です',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error('パスワードが一致しません。'));
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="パスワード(8文字以上・英数字を含む)"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <div className="text-center mt-6">
              <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  shape="round"
                  loading={loading}
              >
                パスワードを変更する
              </Button>
            </div>
          </Form>
        </>
      </Modal>
  );
};
export default UserPasswordChangeModal;
