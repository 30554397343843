import { Form, PageHeader, notification } from "antd";
import React, { useState } from "react";
import { User } from "../../models/user";
import client from "../../core/axios";
import { axiosErrorHandler } from "../../utils/error";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import AppLoading from "../../components/common/AppLoading";
import UserForm from "../../components/user/UserForm";
import _ from 'lodash';

type Props = {};

const UserEdit: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { appUser, updateAuthResult } = useContext(AuthContext);
  const initialValues = {...appUser, tags: appUser?.tags?.map((data) => {
      return {label: data, value: data};
    }) || []};

  if (!appUser) {
    <AppLoading />;
  }

  const onFinish = async (values: Partial<User>) => {
    const _values = {...values, tags: _.uniq(values?.tags?.map((data: any)=>{
        return data?.value;
      }) || [])}
    try {
      setLoading(true);
      await client
        .patch(`/users/${appUser?.id}`, _values)
        .catch(axiosErrorHandler);
      notification.success({ message: "プロフィールを更新しました" });
    } catch (err: any) {
      axiosErrorHandler(err);
    } finally {
      updateAuthResult();
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader title="プロフィール編集" />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        className="px-4"
      >
        <UserForm mode="edit" form={form} loading={loading} />
      </Form>
    </div>
  );
};
export default UserEdit;
