import React, { useContext } from "react";
import { Row, Col, Button, Card } from "antd";
import { Service } from "../../../models/service";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Svg } from "../../common/Svg";
import UserAvatar from "../../user/UserAvatar";
import { getUrlByKey } from "../../../utils/storage";
import { AuthContext } from "../../../contexts/auth";
import nl2br from 'react-nl2br';

type Props = {
  service: Service;
  onClickEdit?: (event: any) => void;
  onClickDelete?: (event: any) => void;
  onClickOrder?: (service: Service) => void;
};

const ServiceDetailCard: React.FC<Props> = ({
  service,
  onClickEdit,
  onClickDelete,
  onClickOrder,
}) => {
  const { appUser } = useContext(AuthContext);
  const isMine = service.userId === appUser?.id;

  const { Suffix } = Svg;
  const navigate = useNavigate();
  return (
    <Card
      className="flex-row"
      hoverable={!!onClickOrder}
      onClick={(evt) => {
        onClickOrder && onClickOrder(service);
      }}
    >
      <Row>
        <Col xs={24}>
          <div className="flex justify-between">
            <div className="font-bold text-base text-lg">{service.title}</div>
            {onClickEdit && (
              <Svg.Edit className="mt-1" style={{flexShrink: 0}} onClick={onClickEdit}/>
            )}
          </div>
          {service?.description && (
            <p className="my-2 text-sm">
              {nl2br(service.description)}
            </p>
          )}
        </Col>
      </Row>
      
      <Row className="w-full items-center mt-4">
        <Col
          span={12}
        >
          <span className="block text-xs" style={{color: '#33333373'}}>必要なロマン</span>
          <div className="flex items-center font-bold">
            <span className="text-md inline-block mr-1">{service.price}</span>
            <Suffix width="14px" height="14px" />
          </div>
        </Col>
        <Col
          span={12}
          className="flex items-center justify-end"
        >
          {(isMine && onClickDelete) ? (
            <a type="link" onClick={onClickDelete}>削除する</a>
          ) : (
            <>
              {onClickOrder && (
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => {
                    navigate(`/users/${service?.user.id}`)
                  }}
                  loading={false}
                  className='py-3'
                  style={{minWidth: '130px'}}
                  disabled={isMine}
                >
                  交換する
                </Button>
              )}
            </>
          )}
          
        </Col>
      </Row>
    </Card>
  );
};

export default ServiceDetailCard;
