import React, { useContext, useState } from "react";
import { Button, Form, Input, PageHeader, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { AuthContext } from "../../contexts/auth";
import { customErrorHandler } from "../../utils/error";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { CognitoUser } from 'amazon-cognito-identity-js';
import FirstPasswordChangeForm from './FirstPasswordChangeForm';
import DefaultForm from './DefaultForm';

const SignIn: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { cognitoUser, updateAuthResult } = useContext(AuthContext);
  const [tmpCognitoUser, setTmpCognitoUser ] = useState(null);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAuthChallange, setIsAuthChallange] = useState(false);

  const onFinishDefault = async (values: any) => {
    try {
      setLoading(true);
      const { email, password } = values;
      const user: any = await Auth.signIn(
        email,
        password,
      );
      setTmpCognitoUser(user);

      // 初回パスワードリセット
      // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#complete-new-password
      if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsAuthChallange(true);
      } else {
        await updateAuthResult();
        navigate("/");
      }
    } catch (err: any) {
      const message = t(`aws.cognito.error.${err.code}`);
      customErrorHandler(message);
    } finally {
      setLoading(false);
    }
  };

  const onFinishPasswordChange = async (values: any) => {
    setLoading(true);
    const { newPassword, newPasswordConfirm } = values;
    if (newPassword !== newPasswordConfirm) {
      customErrorHandler("確認用パスワードが一致しません");
      setLoading(false);
      return;
    }
    try {
      await Auth.completeNewPassword(
        tmpCognitoUser,
        newPassword
      );
      await updateAuthResult();
      navigate("/");
    } catch (err: any) {
      const message = t(`aws.cognito.error.${err.code}`);
      customErrorHandler(message);
    } finally {
      setLoading(false);
    }
  }

  // cognitoログイン済みの場合はログイン後画面へリダイレクト
  useEffect(() => {
    if (cognitoUser) {
      navigate("/");
    }
  }, [cognitoUser, navigate]);

  return (
    <div className="SignIn">
      <PageHeader title="ログイン" />
      {isAuthChallange ? (
        <FirstPasswordChangeForm onFinish={onFinishPasswordChange} loading={loading} />
      ) : (
        <DefaultForm onFinish={onFinishDefault} loading={loading} />
      )}  
    </div>
  );
};

export default SignIn;
