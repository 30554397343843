import Amplify from "aws-amplify";
// https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource

const envCognitoSetting: any = {
  dev: {
    idPoolId: "ap-northeast-1:1d07c907-af34-493d-9147-8c663fc1f5fb",
    userPoolId: "ap-northeast-1_xpyOfQ6D7",
    userPoolClientId: "7rmvinsnbi5n9pmfii5ggrgmou",
    authDomain: "openfirm-dev-oauth.auth.ap-northeast-1.amazoncognito.com",
  },
  stg: {
    idPoolId: "ap-northeast-1:03b4edef-9d9c-4299-a18e-41009096b74d",
    userPoolId: "ap-northeast-1_e2RGcgfP5",
    userPoolClientId: "3l0kbscnvib3ptkabqjofkd9gj",
    authDomain: "openfirm-stg.auth.ap-northeast-1.amazoncognito.com",
  },
  prod: {
    idPoolId: "ap-northeast-1:7eec5cbc-0ff7-4180-a139-bbef0ed7ea96",
    userPoolId: "ap-northeast-1_I4NgvtQEJ",
    userPoolClientId: "5pr4grtuk1alvj1gaoq6fiq8ud",
    authDomain: "openfirm-prod.auth.ap-northeast-1.amazoncognito.com",
  },
};

const env = process.env.REACT_APP_ENV || "dev";
const cognitoSetting = envCognitoSetting[env];

export const amplifyConfig = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: cognitoSetting.idPoolId,

    // REQUIRED - Amazon Cognito Region
    region: "ap-northeast-1",

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: "ap-northeast-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: cognitoSetting.userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: cognitoSetting.userPoolClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: ".yourdomain.com",
    //   // OPTIONAL - Cookie path
    //   path: "/",
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: "strict" | "lax",
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true,
    // },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: "USER_PASSWORD_AUTH",

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: "myCustomValue" },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: cognitoSetting.authDomain,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn:
        process.env.REACT_APP_FRONT_BASE_URL + "/" || "http://localhost:3000/", // 【注意】traling slashは必須
      redirectSignOut:
        process.env.REACT_APP_FRONT_BASE_URL + "/" || "http://localhost:3000/", // 【注意】traling slashは必須
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

Amplify.configure(amplifyConfig);
