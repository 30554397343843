import React, { useState } from "react";
import { Button, Form, Modal } from "antd";
import client from "../../core/axios";
import { axiosErrorHandler } from "../../utils/error";
import { ModalCtrl } from "../../hooks/useModalCtrl";
import { User, Keyword } from "../../models/user";
import KeywordForm from '../../components/user/KeywordForm';

type KeywordEditModalProps = {
  user: User;
  index: number;
  keyword: Keyword;
  modalCtrl: ModalCtrl;
  onComplete: () => void;
};

type FormType = {
  title: string;
  description: string;
};

const KeywordEditModal: React.FC<KeywordEditModalProps> = ({
  user,
  index,
  keyword,
  modalCtrl,
  onComplete,
}) => {
  const title = "キーワードを編集する";
  const [loading, setLoading] = useState(false);
  const { visible, hide } = modalCtrl;
  const [form] = Form.useForm();

  const onFinish = async (params: FormType) => {
    setLoading(true);
    const { createdAt, updatedAt, ...userParams } = user;
    const newKeywords = [...user.keywords || []];
    newKeywords[index] = params;
    const res = await client
      .patch(`/users/${user.id}`, {
        ...userParams,
        keywords: newKeywords
      })
      .catch(axiosErrorHandler);
    setLoading(false);
    if (res) {
      hide();
      onComplete();
    }
  };

  const onClickDelete = async () => {
    const { createdAt, updatedAt, ...userParams } = user;
    const newKeywords = [...user.keywords || []];
    newKeywords.splice(index, 1);
    const res = await client
      .patch(`/users/${user.id}`, {
        ...userParams,
        keywords: newKeywords
      })
      .catch(axiosErrorHandler);

    if (res) {
      hide();
      onComplete();
    }
  };

  return (
    <Modal title={title} visible={visible} footer={null} onCancel={hide}>
      <Form<FormType> onFinish={onFinish} initialValues={keyword} form={form}>
        <KeywordForm mode="edit" form={form} loading={loading} />
         <div className="text-center">
          <Button className="mt-4" type="link" onClick={onClickDelete}>削除する</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default KeywordEditModal;
