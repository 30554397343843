import React, { FC, useEffect, useState } from "react";
import { User } from "../models/user";
import useSWR from "swr";
import { useContext } from "react";
import { AuthContext } from "./auth";

export type ConfigValue = {
  adminUser: User | null;
  adminUserPath: string | null; // adminユーザーのパス（「物資交換」用）
};

export const ConfigContext = React.createContext<ConfigValue>({
  adminUser: null,
  adminUserPath: null,
});

type ConfigProviderProps = {
  children: React.ReactNode;
};

export const ConfigProvider: FC<ConfigProviderProps> = ({
  children,
}: ConfigProviderProps) => {
  const { appUser } = useContext(AuthContext);
  const { data: users } = useSWR(!!appUser ? `/users?type=admin` : null);
  const [adminUser, setAdminUser] = useState<User | null>(null);
  const [adminUserPath, setAdminUserPath] = useState<string | null>(null);

  useEffect(() => {
    if (users && users[0]) {
      setAdminUser(users[0]);
      setAdminUserPath(`/users/${users[0].id}`);
    }
  }, [users]);

  return (
    <ConfigContext.Provider
      value={{
        adminUser,
        adminUserPath,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default {};
