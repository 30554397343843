import React from "react";
import { css } from "@emotion/css";

const listWrapperStyle = css`
  border-radius: 16px;
  padding: 24px;
  max-width: 700px;
  margin: auto;
  background-color: #fff;
`;

type Props = React.HTMLAttributes<HTMLDivElement>;

const AppListWrapper: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <div className={listWrapperStyle} {...rest}>
      {children}
    </div>
  );
};
export default AppListWrapper;
