import React from "react";
import { Button } from "antd";
import useAxiosSWR from "../../hooks/useAxiosSWR";

const Sandbox: React.FC = () => {
  const { data, error } = useAxiosSWR("/test");

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  return (
    <div>
      {JSON.stringify(data)}
      <Button>asdf</Button>
    </div>
  );
};
export default Sandbox;
