import React, { useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { AuthContext } from "../../contexts/auth";
import AppLoading from "../../components/common/AppLoading";

const AuthGuard: React.FC = () => {
  const { cognitoUser, cognitoSession, appUser, loading } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Cognito認証チェック
    if (!loading && (!cognitoUser || !cognitoSession)) {
      navigate("/signin");
    }

    // アプリ認証チェック
    // ユーザー未作成の場合は新規作成画面へ
    if (!loading && (!appUser && location.pathname !== "/users/create")) {
      navigate("/users/create");
    }
  });

  if (loading) {
    return <AppLoading />;
  }

  // Cognito認証チェック
  if (!cognitoUser || !cognitoSession) {
    return <AppLoading />;
  }

  // アプリ認証チェック
  // ユーザー未作成の場合は新規作成画面へ
  if (!appUser && location.pathname !== "/users/create") {
    setTimeout(() => {
      navigate("/users/create");
    }, 0);
    return <AppLoading />;
  }

  console.log({ appUser, cognitoUser, cognitoSession });

  return <Outlet />;
};

export default AuthGuard;
