import React, { HTMLAttributes } from "react";
import { css } from "@emotion/css";

const headerStyle = css`
  font-size: 1.2rem;
  text-align: center;
  color: #d8b960;
  padding-bottom: 20px;
`;

type Props = {
  title: string;
} & React.HTMLAttributes<HTMLDivElement>;

const AppPageInnerHeader: React.FC<Props> = ({ title, ...rest }) => {
  return (
    <div {...rest}>
      <div className={headerStyle}>{title}</div>
    </div>
  );
};
export default AppPageInnerHeader;
