import React, { useEffect, useState } from "react";
import { Form, Modal } from "antd";
import client from "../../core/axios";
import { axiosErrorHandler } from "../../utils/error";
import { ModalCtrl } from "../../hooks/useModalCtrl";
import WorkForm from "../../components/user/WorkForm";
import { User } from '../../models/user';

type WorkAddModalProps = {
  user: User;
  modalCtrl: ModalCtrl;
  onComplete: () => void;
};

type FormType = {
  title: string;
  description: string;
  thumbnailType: 'image' | 'video';
  image?: string;
  video?: string;
};

const initialValues = {
  title: "",
  description: "",
  thumbnailType: "image",
  image: null,
  video: null,
};

const WorkAddModal: React.FC<WorkAddModalProps> = ({
  user,
  modalCtrl,
  onComplete,
}) => {
  const title = "Worksを追加する";
  const [loading, setLoading] = useState(false);
  const { visible, hide } = modalCtrl;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const onFinish = async (params: FormType) => {
    setLoading(true);
    const { createdAt, updatedAt, ...userParams } = user;
    const res = await client.patch(`/users/${user.id}`, {
      ...userParams,
      works: [
        ...user.works || [],
        params,
      ],
    }).catch(axiosErrorHandler);
    setLoading(false);
    if (res) {
      form.resetFields();
      hide();
      onComplete();
    }
  };

  return (
    <Modal title={title} visible={visible} footer={null} onCancel={hide}>
      <Form<FormType>
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <WorkForm mode="create" form={form} loading={loading} />
      </Form>
    </Modal>
  );
};

export default WorkAddModal;
