import useSWR from "swr";
import client from "../core/axios";

const fetcher = (url: string) => client.get(url).then((res) => res.data);

const useAxiosSWR = (url: string) => {
  return useSWR("/users", fetcher);
};

export default useAxiosSWR;
