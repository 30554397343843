import { useState } from "react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3001",
};

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

const client = axios.create(axiosConfig);

declare var window: any;
window.client = client;

client.interceptors.request.use((config) => {
  const idToken = sessionStorage.getItem("idToken");
  if (idToken) {
    config.headers.Authorization = `Bearer ${idToken}`;
  }
  return config;
});

export function useAxiosClient() {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<undefined | AxiosResponse>(
    undefined
  );
  const [error, setError] = useState<undefined | AxiosError>(undefined);
  const request = (requestConfig: AxiosRequestConfig) => {
    setLoading(true);
    client
      .request(requestConfig)
      .then((res) => {
        setResponse(res);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    error,
    request,
    response,
  };
}

export default client;
