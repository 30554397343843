import { Button, Divider, Tag, Typography, Image, Modal } from "antd";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import useModalCtrl from "../../hooks/useModalCtrl";
import ServiceAddModal from "./ServiceAddModal";
import ServiceEditModal from "./ServiceEditModal";
import ServiceDeleteModal from "./ServiceDeleteModal";
import useSWR, { mutate } from "swr";
import { Service } from "../../models/service";
import ServiceDetailCard from "../../components/service/ServiceDetailCard";
import AppLoading from "../../components/common/AppLoading";
import AppBlankMessage from "../../components/common/AppBlankMessage";
import { css } from "@emotion/css";
import UserAvatar from "../../components/user/UserAvatar";
import { getUrlByKey } from "../../utils/storage";
import { Svg } from '../../components/common/Svg';
import VideoPlayer from "./VideoPlayer"
import WorkAddModal from './WorkAddModal';
import WorkEditModal from './WorkEditModal';
import KeywordAddModal from './KeywordAddModal';
import KeywordEditModal from './KeywordEditModal';
import { User, Work, Keyword } from '../../models/user';
import Linkify from 'react-linkify';
import nl2br from 'react-nl2br';

const { Title } = Typography;

const LinkifyTargetBlank: React.FC = ({ children }) => {
  return (
    <Linkify 
      componentDecorator={(decoratedHref: string, decoratedText: string) => {
       return <a href={decoratedHref} target="_blank">{decoratedText}</a>
      }}>{children}</Linkify>
  )
}

const profileContainerStyle = css`
  padding: 24px 0;
  position: relative;
  overflow: hidden;
`;

const profileContainerImageStyle = css`
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  width: 105%;
  height: 105%;
  transform: translate(-50%, -50%);
  filter: blur(5px);
`;

const serviceContainerStyle = css`
  background: #fff;
  padding: 0 0 24px;
`;

const myAreaStyle = css`
  display: flex;
  flex-direction: column;
  background: #F7F5F5;
  font-size: 12px;
  padding: 20px 24px;
  font-weight: bold;

  .myarea-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .dot {
    color: #d8b960;
    margin-right: 4px;
  }
`;

const ContentHeader: React.FC = ({children}) => (
  <div className="text-center text-white pt-4 pb-2 text-lg font-bold mb-10" style={{background: 'rgba(51, 51, 51, 0.85)'}}>
    {children}
    <Divider className="mx-auto mt-2 mb-3" style={{width: '30px', minWidth: '30px', borderTop: '2px solid orange'}}></Divider>
  </div>
)

const UserDetail: React.FC = () => {
  const navigate = useNavigate();
  const { appUser } = useContext(AuthContext);
  const { id } = useParams();
  let userApiPath = `/users/${id}`;
  const { data: user } = useSWR(userApiPath);
  const serviceAddModalCtrl = useModalCtrl();
  const workAddModalCtrl = useModalCtrl();
  const keywordAddModalCtrl = useModalCtrl();
  const serviceListApiPath = user ? `/services?userId=${user.id}` : null;
  const { data: services } = useSWR(serviceListApiPath);

  if (!user) {
    return <AppLoading />;
  }

  // マイページかどうか
  const isMine = id === appUser?.id;

  const onClickAdd = () => {
    serviceAddModalCtrl.show();
  };

  const onComplete = () => {
    mutate(userApiPath);
    mutate(serviceListApiPath);
  };

  return (
    <>
      <div  className={profileContainerStyle} style={{background: 'linear-gradient(to bottom, #fff 1%,#d5ae5f 100%)'}}>
        {/* 背景画像 */}
        <div className={profileContainerImageStyle} style={{backgroundImage: `url(${getUrlByKey(user.backgroundImage) || '/placeholder-background.png'})`}}></div>
        <div className="text-center">
          <UserAvatar src={getUrlByKey(user.image)} style={{border: '2px solid #fff', background: '#d5ae5f'}} size={150} />
        </div>
        <div className="relative text-center mt-5 font-bold text-white text-lg">{user.name}</div>
        <div className="relative text-center mt-2 text-white text-xs">
          {user.organization} / {user.job}
        </div>
        {isMine && (
          <div className="text-center mt-4">
            <Link to={`/users/${user.id}/edit`}>
              <Button type="primary" shape="round" ghost>
                プロフィールを編集
              </Button>
            </Link>
          </div>
        )}
      </div>
      <div className={serviceContainerStyle}>
        {isMine && (
          <div className={myAreaStyle}>
            <div className="myarea-item">
              <div><span className="dot">●</span>会員ID</div>
              <div>{user?.serialNumber || '---'}</div>
            </div>
            <div className="myarea-item mt-2">
              <div><span className="dot">●</span>会員プラン</div>
              <div>{user?.plan || '---'}</div>
            </div>
            <div className="myarea-item mt-2">
              <div><span className="dot">●</span>受付コード(PAVILION入館用)</div>
              <div>{user?.receptionCode || '---'}</div>
            </div>
          </div>
        )}
        <div style={{padding: '24px 24px 48px'}}>
          <div>
            <div className="text-xs block mb-1">名前</div>
            <div className="text-md font-bold block">{user.name}</div>
            <Divider className="my-4 border-1" style={{ borderColor: 'rgba(51, 51, 51, .45)'}} />
          </div>
          <div>
            <div className="text-xs block mb-1">名前(フリガナ)</div>
            <div className="text-md font-bold block">{user.nameKn}</div>
            <Divider className="my-4 border-1" style={{ borderColor: 'rgba(51, 51, 51, .45)'}} />
          </div>
          {user?.organization && (
            <div>
              <div className="text-xs block mb-1">会社名/所属</div>
              <div className="text-md font-bold block">{user.organization}</div>
              <Divider className="my-4 border-1" style={{ borderColor: 'rgba(51, 51, 51, .45)'}} />
            </div>
          )}

          <div>
            <div className="text-xs block mb-1">職種</div>
            <div className="text-md font-bold block">{user.job}</div>
            <Divider className="my-4 border-1" style={{ borderColor: 'rgba(51, 51, 51, .45)'}} />
          </div>
          <div>
            <div className="text-xs block mb-1">経歴</div>
            <div className="text-md font-bold block"><LinkifyTargetBlank>{nl2br(user?.description)}</LinkifyTargetBlank></div>
            <Divider className="my-4 border-1" style={{ borderColor: 'rgba(51, 51, 51, .45)'}} />
          </div>
          {user?.url && (
            <div>
              <div className="text-xs block mb-1">URL</div>
              <div className="text-md font-bold block">
                <a href={user?.url} target="_blank">{user?.url}</a>
              </div>
              <Divider className="my-4 border-1" style={{ borderColor: 'rgba(51, 51, 51, .45)'}} />
            </div>
          )}
          {(user?.instagramUrl || user?.facebookUrl || user?.slackUrl) && (
            <div>
              <div className="text-xs block mb-1">SNS</div>
              <div className="text-md font-bold block">
                <div className="flex">
                  {user?.instagramUrl && <a href={user?.instagramUrl} target="_blank" rel="noreferrer"><Svg.Instagram width="40px" height="40px"/></a> } 
                  {user?.facebookUrl && <a href={user?.facebookUrl} target="_blank" rel="noreferrer"><Svg.FaceBook width="40px" height="40px"/></a>}
                  {user?.slackUrl && <a href={user?.slackUrl} target="_blank" rel="noreferrer"><Svg.Slack width="40px" height="40px"/></a>}
                </div>
              </div>
              <Divider className="my-4 border-1" style={{ borderColor: 'rgba(51, 51, 51, .45)'}} />
            </div>
          )}
          <div className="flex flex-wrap mt-9">
            {user.tags && user.tags.map((tag: string, i: number) => {
              return (
                <Link key={i} to={`/users?tag=${tag}`}>
                  <Tag key={i} className="border-none bg-transparent font-bold cursor-pointer" style={{ color: "#D8B960" }}>#{tag}</Tag>
                </Link>
              )
            })}
          </div>
        </div>
        {((user?.works && user?.works.length) || isMine) && (
          <div>
            <ContentHeader>
              WORKS
            </ContentHeader>
            <div className="mb-4" style={{padding: '0 24px 12px'}}>
              {(!user?.works || !user?.works.length) && (
                <AppBlankMessage message="Worksがまだ追加されていません" />
              )}
              {user.works && user.works.map((work: Work, index: number) => {
                return (
                  <>
                    {index !== 0 && <Divider className="mb-4 mb-0 border-1" style={{ borderColor: 'rgba(51, 51, 51, .45)'}} />}
                    <WorkListItem work={work} user={user} isMine={isMine} index={index} onComplete={onComplete}/>
                  </>
                );
              })}
            </div>
            {isMine && (
              <div className="text-center mb-10">
                <div onClick={() => {
                  workAddModalCtrl.show();
                }} className="border-none bg-transparent cursor-pointer text-md flex items-center justify-center font-bold" style={{ color: "#D8B960" }}>
                  <Svg.Plus className="mr-2"/>実績を追加する
                </div>
              </div>
            )}
          </div>
        )}

        {(user?.videoUrl || user?.articleUrl) && (
          <div>
            <ContentHeader>
              クリエイター図鑑
            </ContentHeader>
            <div className="mb-4" style={{padding: '0 24px 12px'}}>
              {user?.videoUrl && (
                <div>
                  <VideoPlayer url={user?.videoUrl} />
                </div>
              )}
              
              {user?.videoUrl && (
                <>
                  <div className="text-xs block mt-5 mb-1">動画</div>
                  <div className="text-md font-bold block">
                    <a href={user?.videoUrl} target="_blank">{user?.videoUrl}</a>
                  </div>
                  <Divider className="mt-4 mb-0 border-1" style={{ borderColor: 'rgba(51, 51, 51, .45)'}} />
                </>
              )}
              {user?.articleUrl && (
                <>
                  <div className="text-xs block mt-5 mb-1">記事</div>
                  <div className="text-md font-bold block">
                    <a href={user?.articleUrl} target="_blank">{user?.articleUrl}</a>
                  </div>
                  <Divider className="mt-4 mb-0 border-1" style={{ borderColor: 'rgba(51, 51, 51, .45)'}} />
                </>
              )}
            </div>
          </div>
        )}
        {((services && services.length > 0) || isMine) && (
          <div>
            <ContentHeader>
              ロマン<Svg.Suffix width="17px" height="16px" fill="#fff" />
            </ContentHeader>
            <div style={{padding: '0 24px'}}>
              {services === undefined && <AppLoading />}
              {services && !services.length && (
                <AppBlankMessage message="サービスがまだ追加されていません" />
              )}
              {services &&
                services.map((service: Service) => (
                  <ServiceListItem
                    service={service}
                    isMine={isMine}
                    onComplete={onComplete}
                  />
                ))}
            </div>
            {/* TODO front pagination */}
            {/* <div className="text-center mb-10">
              <div onClick={() => {}} className="border-none bg-transparent cursor-pointer text-md font-bold" style={{ color: "#D8B960" }}>
                ＋ MORE
              </div>
            </div> */}
            {isMine && (
              <div className="text-center mb-10">
                <div onClick={onClickAdd} className="border-none bg-transparent cursor-pointer text-md flex items-center justify-center font-bold" style={{ color: "#D8B960" }}>
                  <Svg.Plus className="mr-3"/>サービスを追加する
                </div>
              </div>
            )}
          </div>
        )}
        {((user?.keywords && user?.keywords.length > 0) || isMine) && (
          <div>
            <ContentHeader>
              ABOUT ME
            </ContentHeader>
            <div style={{padding: '0 24px'}}>
              {(!user?.keywords || !user?.keywords.length) && (
                <AppBlankMessage message="キーワードがまだ追加されていません" />
              )}
              {user?.keywords && user?.keywords.map((keyword: Keyword, index: number) => {
                return (
                  <KeywordListItem keyword={keyword} user={user} onComplete={onComplete} index={index} isMine={isMine} />
                );
              })}
              {isMine && (
                <div className="text-center mb-10">
                  <div onClick={() => {
                    keywordAddModalCtrl.show();
                  }} className="border-none bg-transparent cursor-pointer text-md flex items-center justify-center font-bold" style={{ color: "#D8B960" }}>
                    <Svg.Plus className="mr-2"/>キーワードを追加する
                  </div>
                </div>
              )}
              
              <div className="text-center">
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  onClick={() => {
                    navigate('/users')
                  }}
                  ghost
                >
                  メンバー一覧に戻る
                </Button>
              </div>
            </div>
          </div>
        )}
        <ServiceAddModal modalCtrl={serviceAddModalCtrl} onComplete={onComplete} />
        <WorkAddModal user={user} modalCtrl={workAddModalCtrl} onComplete={onComplete} />
        <KeywordAddModal user={user} modalCtrl={keywordAddModalCtrl} onComplete={onComplete} />
      </div>
    </>
  );
};

type WorkListItemProps = {
  work: Work;
  user: User;
  index: number;
  isMine: boolean;
  onComplete(): void;
};

const WorkListItem: React.FC<WorkListItemProps> = ({ work, user, isMine, index, onComplete }) => {
  const workEditModalCtrl = useModalCtrl();
  const workDeleteModalCtrl = useModalCtrl();

  const onClickEdit = () => {
    workEditModalCtrl.show();
  }

  return (
    <>
      <div className="block sm:flex">
        <div className="w-full sm:w-1/2 mr-0 sm:mr-4">
          <Title className="text-xl flex justify-between block sm:hidden">
            {work.title}
            {isMine && (
              <Svg.Edit className="mt-1" style={{flexShrink: 0}} onClick={onClickEdit} />
            )}
          </Title>
          <div>
            {work.thumbnailType === 'video' && <VideoPlayer url={work.video}/>}
            {work.thumbnailType === 'image' && <Image width="100%" height="200px" src={getUrlByKey(work.image) || ""} fallback="/placeholder-no-image.png" preview={false} style={{objectFit: 'cover'}} /> }
          </div>
        </div>
        <div className="w-full sm:w-1/2">
          <Title className="text-xl flex justify-between hidden sm:block">
            {work.title}
            {isMine && (
              <Svg.Edit className="ml-2 mt-1" style={{flexShrink: 0}} onClick={onClickEdit} />
            )}
          </Title>
          <p className="mt-5 text-sm">
            <LinkifyTargetBlank>{nl2br(work.description)}</LinkifyTargetBlank>
          </p>
        </div>
      </div>
      <WorkEditModal modalCtrl={workEditModalCtrl} user={user} index={index} onComplete={onComplete} work={work} />
    </>
  );
};

type KeywordListItemProps = {
  keyword: Keyword;
  user: User;
  onComplete: () => void;
  index: number;
  isMine: boolean;
}

const KeywordListItem: React.FC<KeywordListItemProps> = ({ keyword, user, isMine, index, onComplete }) => {
  const keywordEditModalCtrl = useModalCtrl();

  return (
    <>
      <div className="mb-5">
        <div className="font-bold mb-1 flex justify-between">
          {keyword.title}
          {isMine && (
            <Svg.Edit className="mt-1" style={{flexShrink: 0}} onClick={() => {
              keywordEditModalCtrl.show();
            }}/>
          )}
        </div>
        <div>{keyword.description}</div>
      </div>
      <KeywordEditModal modalCtrl={keywordEditModalCtrl} user={user} index={index} onComplete={onComplete} keyword={keyword} />
    </>
  );
};

type ServiceListItemProps = {
  service: Service;
  onComplete: () => void;
  isMine: boolean;
};
const ServiceListItem: React.FC<ServiceListItemProps> = ({
  service,
  isMine,
  onComplete,
}) => {
  const navigate = useNavigate();
  const serviceEditModalCtrl = useModalCtrl();
  const serviceDeleteModalCtrl = useModalCtrl();

  const onClickEdit = () => {
    serviceEditModalCtrl.show();
  };

  const onClickOrder = (service: Service) => {
    navigate(`/order/create?serviceId=${service.id}`);
  };

  return (
    <div className="mt-2 mb-5">
      {isMine ? (
        <ServiceDetailCard
          service={service}
          onClickEdit={onClickEdit}
          onClickDelete={() => {
            serviceDeleteModalCtrl.show();
          }}
        />
      ) : (
        <ServiceDetailCard service={service} onClickOrder={onClickOrder} />
      )}
      <ServiceEditModal
        service={service}
        modalCtrl={serviceEditModalCtrl}
        onComplete={onComplete}
      />
      <ServiceDeleteModal
        service={service}
        modalCtl={serviceDeleteModalCtrl}
        onComplete={onComplete}
      />
    </div>
  );
};

export default UserDetail;
