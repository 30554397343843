import React, {useState} from "react";
import {
  Form,
  FormInstance,
  Input,
  Button,
  Tooltip,
  Typography,
  Space,
} from "antd";
import client from "../../../core/axios";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/auth";
import UserUploadImage from "../UserUploadImage";
import Select from 'react-select/async-creatable';
import { reactMultiSelectStyle, loadSuggestions } from "../../../utils/reactSelect";
import UploadImage from '../../common/UploadImage';
import { getUrlByKey } from '../../../utils/storage';

type UserFormProps = {
  mode: "create" | "edit";
  form: FormInstance;
  loading: boolean;
};

const UserForm: React.FC<UserFormProps> = ({ mode, form, loading }) => {
  const { appUser, updateAuthResult } = useContext(AuthContext);
  const [uploading, setUploading] = useState(false);
  const tags = null;

  // 重複チェック
  const validateSerialNumber = async () => {
    const serialNumber = form.getFieldValue("serialNumber");
    if (serialNumber === "" || serialNumber.length !== 3) {
      return false;
    }
    const res = await client.get(
      `/users?serialNumber=${serialNumber}&ignoreUserId=${appUser?.id}`
    );

    if (res.data && res.data.length > 0) {
      throw new Error("会員IDが重複しています");
    }
  };

  return (
    <>
      <Form.Item
        label="名前"
        name="name"
        rules={[
          { required: true, message: "この項目は必須です" },
          { max: 30, message: "30文字以内で設定してください" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="名前(フリガナ)"
        name="nameKn"
        rules={[
          { required: true, message: "この項目は必須です" },
          { max: 30, message: "30文字以内で設定してください" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="会社名/所属"
        name="organization"
        rules={[
          { max: 30, message: "30文字以内で設定してください" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="職種"
        name="job"
        rules={[
          { required: true, message: "この項目は必須です" },
          { max: 30, message: "30文字以内で設定してください" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="URL"
        name="url"
      >
        <Input type="url" />
      </Form.Item>
      <Form.Item
        label="経歴"
        name="description"
        rules={[{ max: 1000, message: "1000文字以内で入力してください" }]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item
        label="タグ"
        name="tags"
      >
        <Select
          styles={reactMultiSelectStyle}
          value={tags}
          loadOptions={loadSuggestions}
          noOptionsMessage={() => "候補がありません"}
          isClearable
          isMulti
          placeholder="お好きなタグを入力してください"
          formatCreateLabel={(inputValue) => `タグ "${inputValue}" を登録する`}
        />
      </Form.Item>
      <Form.Item label="プロフィール画像" name="image">
        <UserUploadImage
          src={appUser?.image && getUrlByKey(appUser.image)}
          onUploadStart={() => {
            setUploading(true);
          }}
          onFinish={() => {
            setUploading(false);
          }}
          onDelete={() => {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              image: null,
            })
          }}
          onSuccess={(filePath) => {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              image: filePath,
            });
          }}
        />
      </Form.Item>
      <Form.Item label="背景画像" name="backgroundImage">
        <UploadImage
          src={appUser?.backgroundImage && getUrlByKey(appUser.backgroundImage)}
          onUploadStart={() => {
            setUploading(true);
          }}
          onFinish={() => {
            setUploading(false);
          }}
          onDelete={() => {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              backgroundImage: null,
            });
          }}
          onSuccess={(filePath: string) => {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              backgroundImage: filePath,
            });
          }}
        />
      </Form.Item>
      <Form.Item>
        <div className="text-center">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            shape="round"
            loading={loading}
            disabled={uploading}
          >
            {mode === "create" && "登録"}
            {mode === "edit" && "更新"}
          </Button>
        </div>
      </Form.Item>
    </>
  );
};
export default UserForm;
