import { Button, Form, Select } from "antd";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import useSWR from "swr";
import AppContainer from "../../components/common/AppContainer";
import { downloadCsv } from "../../utils/csv";
const { Option } = Select;

const Environment: React.FC = () => {
  const transactionQuery = useSWR("/transactions");
  // リリース月(2021年9月)〜当月まで選択可
  const monthDates = useMemo(() => {
    const _monthDates = [];
    for (
      let date = dayjs("202109");
      date <= dayjs();
      date = date.add(1, "month")
    ) {
      _monthDates.push(date);
    }
    return _monthDates;
  }, []);

  const onClickDownload = () => {
    downloadCsv(transactionQuery.data, [
      { key: "kind", label: "種類" },
      { key: "userId", label: "ユーザーID" },
      { key: "amount", label: "ロマン" },
    ]);
  };

  return (
    <div>
      <AppContainer>
        <Form>
          <Form.Item label="対象年月">
            <Select>
              {monthDates.map((date) => {
                return (
                  <Option value={date.format("YYYYMM")}>
                    {date.format("YYYY年MM月")}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>

        <div>
          <Button type="primary" onClick={onClickDownload}>
            CSVエクスポート
          </Button>
        </div>
      </AppContainer>
    </div>
  );
};

export default Environment;
