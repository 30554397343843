const storageBasePaths: any = {
  dev: "http://openfirm-stg-storage.s3.amazonaws.com",
  stg: "http://openfirm-stg-storage.s3.amazonaws.com",
  prod: "http://openfirm-prod-storage.s3.amazonaws.com",
};

const env = process.env.REACT_APP_ENV || "dev";
const storageBasePath = storageBasePaths[env];

export const getUrlByKey = (key?: string) => {
  if (!key) {
    return null;
  }
  return `${storageBasePath}/${key}`;
};
