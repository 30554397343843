import React, { useContext } from "react";
import { PageHeader, Tabs } from "antd";
import AppContainer from "../../components/common/AppContainer";
import Roman from "./Roman";
import Environment from "./Environment";
import UserList from "./UserList";
import TransactionExport from "./TransactionExport";
import { AuthContext } from "../../contexts/auth";

const { TabPane } = Tabs;

const Debug: React.FC = () => {
  const { appUser } = useContext(AuthContext);
  return (
    <div>
      <PageHeader title="管理者機能" />
      <AppContainer>
        <Tabs defaultActiveKey="1">
          <TabPane tab="ロマン" key="1">
            <Roman />
          </TabPane>
          <TabPane tab="ユーザー一覧" key="2">
            <UserList />
          </TabPane>
          <TabPane tab="取引履歴エクスポート" key="3">
            <TransactionExport />
          </TabPane>
          {appUser?.systemAdminFlag && (
            <TabPane tab="システム環境変数" key="4">
              <Environment />
            </TabPane>
          )}
        </Tabs>
      </AppContainer>
    </div>
  );
};

export default Debug;
