import React, { useState } from "react";
import {
  Form,
  FormInstance,
  Input,
  Button,

  InputNumber,
  Radio,
} from "antd";
import UploadImage from '../../common/UploadImage';
import { worker } from 'cluster';
import { getUrlByKey } from '../../../utils/storage';

type WorkFormProps = {
  mode: "create" | "edit";
  form: FormInstance;
  loading: boolean;
};

const WorkForm: React.FC<WorkFormProps> = ({ mode, form, loading }) => {
  return (
    <>
      <Form.Item
        name="title"
        label="タイトル"
        rules={[
          { required: true, message: "この項目は必須です" },
          { max: 60, message: "60文字以内で設定してください" }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="説明"
        rules={[
          { required: true, message: "この項目は必須です" },
        ]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item
        name="thumbnailType"
        label="サムネイル表示"
        help="画像・動画URLが設定されない場合は No Image の画像が表示されます"
      >
        <Radio.Group>
          <Radio value="image">画像</Radio>
          <Radio value="video">動画</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="画像" name="image">
        <UploadImage
          // TODO front
          src={getUrlByKey(form.getFieldValue('image')) || null}
          // onUploadStart={() => {
          //   setUploading(true);
          // }}
          // onFinish={() => {
          //   setUploading(false);
          // }}
          onDelete={() => {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              image: null,
            });
          }}
          onSuccess={(filePath: string) => {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              image: filePath,
            });
          }}
        />
      </Form.Item>
      <Form.Item label="動画URL" name="video" extra="YoutubeかVimeoの動画URL">
        <Input type="url" />
      </Form.Item>
      <Form.Item>
        <div className="text-center mt-4">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            shape="round"
            loading={loading}
          >
            {mode === "create" && "追加する"}
            {mode === "edit" && "更新する"}
          </Button>
        </div>
      </Form.Item>
    </>
  );
};
export default WorkForm;
