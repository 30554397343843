import { useState } from "react";

export type ModalCtrl = {
  hide: () => void;
  visible: boolean;
  show: () => void;
};

const useModalCtrl = (): ModalCtrl => {
  const [visible, setVisible] = useState(false);

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  return {
    visible,
    show,
    hide,
  };
};

export default useModalCtrl;
