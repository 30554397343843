// https://github.com/pbeshai/use-query-params/issues/108#issuecomment-785209454 からコピーして改変
// react-router v6ではRouteをそのまま使えないのでアダプターを作成して使用
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */
const RouteAdapter: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(to: any) {
        navigate(to, { replace: true, state: location.state });
      },
      push(to: any) {
        navigate(to, { replace: false, state: location.state });
      },
    }),
    [navigate, location.state]
  );
  if (children == null || typeof children !== "function") {
    return null;
  }
  return children({ history: adaptedHistory, location });
};

export default RouteAdapter;
