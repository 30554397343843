import React from "react";
import { Form, Select, Button, InputNumber, notification } from "antd";
import { useAxiosClient } from "../../core/axios";
import { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import { TransactionKind } from "../../models/transaction";

type RomanFormType = {
  amount: number;
  kind: TransactionKind;
};

const Roman: React.FC = () => {
  const [form] = Form.useForm();
  const { request, loading } = useAxiosClient();
  const initialValues = {
    amount: 100,
    kind: "original",
  };
  return (
    <Form<RomanFormType>
      initialValues={initialValues}
      onFinish={async (data) => {
        await request({
          method: "POST",
          url: "/debug/roman",
          data,
        });
        notification.success({ message: `${data.amount}ロマン追加しました` });
        form.resetFields();
      }}
    >
      <div>任意の数量でロマン増減ができます</div>
      <Form.Item name="amount" className="mt-2">
        <InputNumber min={-10000} max={10000} />
      </Form.Item>
      <Form.Item name="kind" className="mt-2">
        <Select>
          <Select.Option value="original">ちょっとお願いロマン</Select.Option>
          <Select.Option value="gift">Thank youロマン</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary" loading={loading}>
          実行
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Roman;
