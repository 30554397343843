import {StylesConfig} from 'react-select';
import client from "../core/axios";
import {debounce} from "lodash";

export type OptionProps = { label: string, value: string };
const grayText = 'rgba(51, 51, 51, 0.85)';
export const reactSelectStyle: StylesConfig<{}, false> = {
  control: (styles) => {
    return {
      ...styles,
      backgroundColor: '#e6e6e6',
      borderRadius: '10px',
      border: 'none',
      ':focus': {
        outlineColor: 'none',
        boxShadow: '0 0 2px 0 #d8b960'
      }
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: '#bfbfbf'
    }
  }
};

export const reactMultiSelectStyle: StylesConfig<OptionProps, true> = {
  control: (styles) => {
    return {
      ...styles,
      backgroundColor: '#e6e6e6',
      borderRadius: '10px',
      border: 'none',
      ':focus': {
        outlineColor: 'none',
        boxShadow: '0 0 2px 0 #d8b960'
      }
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: '#bfbfbf'
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      border: '1px solid #d8b960'
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: grayText,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: grayText,
  }),
};

export const loadSuggestions = debounce((inputValue: string, callback: any) => {
  client.get(`/tags?tag=${inputValue}`).then(res => {
    if (res && res.data) {
      callback(res.data.map((data: {name: string}) => {
        return {label: data.name, value: data.name};
      }));
    }
  });
}, 500)

