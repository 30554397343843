/* eslint-disable import/extensions */
import React, { useContext } from "react";
import { Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AuthContext } from "../../contexts/auth";
import { Svg } from "../../components/common/Svg";
import { useState } from "react";
import useModalCtrl from "../../hooks/useModalCtrl";
import UserPasswordChangeModal from "./UserPasswordChangeModal";
import { getUrlByKey } from '../../utils/storage';

const headerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 24px;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;

  .logo {
    max-width: 177px;
    display: inline-flex;
    align-items: center;

    svg {
      width: 100%;
    }
  }
`;

const Header: React.FC = () => {
  const { appUser, cognitoUser } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);

  return (
    <header className={headerStyle}>
      <Link className="logo" to="/">
        <Svg.Logo />
      </Link>
      {(appUser || cognitoUser) && (
        <Dropdown
          overlay={
            <UserMenu
              onClick={() => {
                setVisible(false);
              }}
            />
          }
          visible={visible}
        >
          <a
            onClick={() => {
              setVisible(!visible);
            }}
          >
            {appUser?.image ? (
              <Avatar size={40} src={getUrlByKey(appUser.image)} />
            ) : (
              <Avatar size={40} icon={<UserOutlined />} />
            )}
          </a>
        </Dropdown>
      )}
    </header>
  );
};

const UserMenu: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { signOut, appUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const updatePasswordModal = useModalCtrl();

  const onClickSignout = async () => {
    try {
      await signOut();
      navigate("/signin");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Menu>
        {appUser && (
          <>
            <Menu.Item key="0" onClick={onClick}>
              <Link to={`/users/${appUser.id}`}>マイページ</Link>
            </Menu.Item>
            <Menu.Item key="1">
              <a
                  onClick={() => {
                    updatePasswordModal.show();
                    onClick();
                  }}
              >
                パスワード変更
              </a>
            </Menu.Item>
          </>
        )}
        {appUser && (appUser.type === "admin" || appUser.systemAdminFlag) && (
            <Menu.Item key="2" onClick={onClick}>
              <Link to="/debug">管理者機能</Link>
            </Menu.Item>
        )}
         <Menu.Item key="2">
           <a
               onClick={() => {
                 onClickSignout();
                 onClick();
               }}
           >
             ログアウト
           </a>
         </Menu.Item>
      </Menu>
      <UserPasswordChangeModal modalCtl={updatePasswordModal} />
    </>
  );
};

export default Header;
