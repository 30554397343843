import React from "react";
import { Button, Form, Input, Typography } from "antd";
import { Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Auth } from "aws-amplify";

type Props = {
  onFinish(values: any): void;
  loading: boolean;
}

// 通常のログインフォーム
const DefaultForm: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const { Text } = Typography;

  return (
    <>  
      <Form
        layout="vertical"
        form={form}
        onFinish={props.onFinish}
        className="px-8 md:px-4"
      >
        {/* 自己サインアップ廃止に伴い削除 */}
        {/* <Text>
          アカウントをお持ちでない場合は
          <Link className="underline" to="/signup">
            新規登録
          </Link>
        </Text> */}
        {/* 通常ログイン */}
        <Form.Item
            className="mt-4"
            label="メール"
            name="email"
            rules={[
            { required: true, message: "メールアドレスは必須です" },
            {
                type: "email",
                message: "メールアドレスの形式で入力してください",
            },
            ]}
        >
            <Input type="email" placeholder="example@openfirm.com" />
        </Form.Item>
        <Form.Item
            label="パスワード"
            name="password"
            rules={[{ required: true, message: "パスワードは必須です" }]}
        >
            <Input.Password
            placeholder="パスワード(8文字以上・英数字を含む)"
            iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            />
        </Form.Item>
        <Form.Item>
            <div className="text-center mt-4">
            <Button
                className="account"
                htmlType="submit"
                type="primary"
                size="large"
                shape="round"
                loading={props.loading}
                block
            >
                ログイン
            </Button>
            </div>
        </Form.Item>
      </Form>
      <div className="text-center mt-4">
        パスワードをお忘れの場合は
        <Link to="/password-forget">こちら</Link>
      </div>
     {/* 自己サインアップ廃止に伴い削除 */}
      {/* <span className="block my-5 text-center text-gray-400">または</span> */}
      {/* <div className="mt-4 px-4">
        <div className="text-center">
          <Button
            className="account"
            size="large"
            shape="round"
            block
            ghost
            onClick={() => {
              // @ts-ignore
              Auth.federatedSignIn({ provider: "Facebook" });
            }}
          >
            Facebookでログイン
          </Button>
        </div>
        <div className="text-center mt-4">
          <Button
            className="account"
            size="large"
            shape="round"
            block
            onClick={() => {
              // @ts-ignore
              Auth.federatedSignIn({ provider: "Google" });
            }}
            ghost
          >
            Googleでログイン
          </Button>
        </div>
      </div> */}
    </>
  );
};

export default DefaultForm;
