import React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";

type Props = ReactPlayerProps;

const VideoPlayer: React.FC<Props> = (props) => {
  return (
    <div className='relative' style={{paddingTop: '62.5%'}}>
      {props.url ? (
        // Vimeoの場合はcontrols=trueが必須
        // https://github.com/cookpete/react-player#props
        // For Vimeo videos, hiding controls must be enabled by the video owner.
        <ReactPlayer {...props} controls={true} width="100%" height="100%" className="absolute top-0 left-0" />
      ) : (
        <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold text-xl' style={{background: 'rgba(51, 51, 51, 0.45)'}}>
          No image
        </div>
      )}

    </div>
  );
}

export default VideoPlayer;