import _ from 'lodash';
import { PageHeader, Card, Col, Tag, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons'
import React, { useMemo } from "react";
import useSWR from "swr";
import { User } from "../../models/user";
import AppContainer from "../../components/common/AppContainer";
import { useNavigate } from "react-router-dom";
import AppLoading from "../../components/common/AppLoading";
import { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import UserAvatar from "../../components/user/UserAvatar";
import AppListWrapper from "../../components/common/AppListWrapper";
import { useState } from "react";
import { getUrlByKey } from '../../utils/storage';

const getParams = () => {
  const search = window.location.search;
  const getParams = new URLSearchParams(search);
  const tag = getParams.get('tag');
  const query = getParams.get('query') || '';
  return {
    tag,
    q: query
  }
}

const isIncludeAnyCase = (a: string, b: string) => {
  return a.toLowerCase().includes(b.toLowerCase());
}

const UserList: React.FC = () => {
  const { tag, q } = getParams();
  let url = '/users?type=normal';
  const navigate = useNavigate()
  let selectedTag = null;
  if (tag) {
    url += `&tag=${tag}`;
    selectedTag = {label: tag, value: tag};
  }
  if (q) {
    url += `&query=${q}`;
  }
  const { data } = useSWR(url);
  const { appUser } = useContext(AuthContext);
  const [query, setQuery] = useState("");
  const onChange = (evt: any) => {
    const query = evt.target.value;
    setQuery(query);
  };

  let filteredUsers: User[] = [];

  filteredUsers = data;

  // 検索
  if (query !== "" && filteredUsers) {
    filteredUsers = filteredUsers.filter((u) => {
      // 氏名
      if (isIncludeAnyCase(u.name, query)) {
        return true;
      }

      // 氏名かな
      if (isIncludeAnyCase(u.nameKn, query)) {
        return true;
      }

      // 会社名
      if (u?.organization && isIncludeAnyCase(u.organization, query)) {
        return true;
      }

      // タグ
      if (
        u?.tags && u.tags?.find((t) => (isIncludeAnyCase(t, query) || isIncludeAnyCase(t, query.replace('#', ''))))
      ) {
        return true;
      }

      return false;
    });
  }

  // ユーザーの表示順をランダムにする（不公平感のないようにユーザー順を固定化しない意図）
  filteredUsers = _.shuffle(filteredUsers);

  // 表示ユーザーに紐づくタグの中から上位20件のタグを表示
  const recommendTags: string[] = useMemo(() => {
    const tags: { name: string, count: number }[] = [];
    filteredUsers.forEach(u => {
      if (u?.tags && u.tags.length) {
        u.tags.forEach((tagName: string) => {
          const tag = tags.find((t2) => (t2.name === tagName));

          if (!tag) {
            tags.push({
              name: tagName,
              count: 0,
            })
          } else {
            tag.count += 1;
          }
        }); 
      }
    });
    const sortedTags = _.orderBy(tags, 'count', 'desc')
    return sortedTags.map(t => t.name).slice(0, 20);
  }, [filteredUsers]);

  if (data === undefined || !appUser) {
    return <AppLoading />;
  }

  return (
    <div>
      <PageHeader title={`MEMBER${tag ? `#${tag}` : ''}`} />
      <AppContainer>
        <div>
          <Input onChange={onChange} placeholder="名前orタグで検索" prefix={<SearchOutlined style={{color: 'rgba(0,0,0, .2)'}}/>}/>
        </div>
        <div className="mt-4 mb-6 flex flex-wrap">
          {recommendTags && recommendTags.length > 0 && recommendTags.map(tag => {
            return (
              <Tag onClick={() => {
                navigate(`/users?tag=${tag}`);
              }} className="border-none bg-transparent font-bold cursor-pointer" style={{ color: "#D8B960" }}>#{tag}</Tag>
            );
          })}
        </div>
        {filteredUsers.length === 0 && <div>該当のメンバーはいません</div>}
        {filteredUsers && filteredUsers.length > 0 && (
          <div className="mt-4">
            <AppListWrapper>
              <div className="flex justify-start flex-wrap w-full">
                {
                  filteredUsers.map((item: User) => {
                    // 名前と画像をクリッカブルとする
                    const onClick = () => {
                      navigate(`/users/${item.id}`)
                    }
                    return (
                      <Col className="w-2/4 sm:w-2/6 md:w-3/12 min-w-[120px] m-0 mb-3">
                        <Card
                          hoverable
                          className="pt-3 border-none hover:shadow-none w-full text-center"
                          cover={<div onClick={onClick}><UserAvatar src={getUrlByKey(item.image)} size={100} /></div>}
                        >
                          <Card.Meta
                            title={<div className='text-xs break-all whitespace-normal'>{item?.organization ? `${item.organization} / ${item.job}` : item.job}</div>}
                            description={<div onClick={onClick} className='text-sm break-all whitespace-normal text-black'>{item.name}</div>}
                          />
                        </Card>
                      </Col>
                    )
                  })
                }
              </div>
            </AppListWrapper>
          </div>
        )}
      </AppContainer>
    </div>
  );
};
export default UserList;
