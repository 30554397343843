import React, { ReactNode } from "react";
import { SWRConfig } from "swr";
import client from "./axios";

const CustomSWRConfig: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <SWRConfig
      value={{
        fetcher: (url: string) => client.get(url).then((res) => res.data),
        revalidateOnMount: true,
        revalidateOnFocus: false,
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default CustomSWRConfig;
