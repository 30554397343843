import React, { useState } from "react";
import { Form, Modal } from "antd";
import client from "../../core/axios";
import { axiosErrorHandler } from "../../utils/error";
import { ModalCtrl } from "../../hooks/useModalCtrl";
import { User } from '../../models/user';
import KeywordForm from '../../components/user/KeywordForm';

type KeywordAddModalProps = {
  user: User;
  modalCtrl: ModalCtrl;
  onComplete: () => void;
};

type FormType = {
  title: string;
  description: string;
};

const initialValues = {
  title: "",
  description: "",
  thumbnailType: "image",
  image: null,
  video: null,
};

const KeywordAddModal: React.FC<KeywordAddModalProps> = ({
  user,
  modalCtrl,
  onComplete,
}) => {
  const title = "キーワードを追加する";
  const [loading, setLoading] = useState(false);
  const { visible, hide } = modalCtrl;
  const [form] = Form.useForm();

  const onFinish = async (params: FormType) => {
    setLoading(true);
    const { createdAt, updatedAt, ...userParams } = user;
    const res = await client.patch(`/users/${user.id}`, {
      ...userParams,
      keywords: [
        ...user.keywords || [],
        params,
      ],
    }).catch(axiosErrorHandler);
    setLoading(false);
    if (res) {
      form.resetFields();
      hide();
      onComplete();
    }
  };

  return (
    <Modal title={title} visible={visible} footer={null} onCancel={hide}>
      <Form<FormType>
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <KeywordForm mode="create" form={form} loading={loading} />
      </Form>
    </Modal>
  );
};

export default KeywordAddModal;
