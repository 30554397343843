import React from "react";
import { Button, Form, Input, Typography } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const { Text } = Typography;

type Props = {
  onFinish(values: any): void;
  loading: boolean;
}

const FirstPasswordChangeForm: React.FC<Props> = (props) => {
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={props.onFinish}
      className="px-8 md:px-4"
    >
      {/* 初回ログイン（パスワード強制変更） */}
      <Text>
        パスワードを設定してください
      </Text>
      <div className="mt-4"></div>
      <Form.Item
          label="新しいパスワード"
          name="newPassword"
          rules={[{ required: true, message: "パスワードは必須です" }]}
      >
          <Input.Password
          placeholder="パスワード(8文字以上・英数字を含む)"
          iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          />
      </Form.Item>
      <Form.Item
          label="新しいパスワード(確認用)"
          name="newPasswordConfirm"
          rules={[{ required: true, message: "確認用パスワードは必須です" }]}
      >
          <Input.Password
          placeholder="パスワード(8文字以上・英数字を含む)"
          iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          />
      </Form.Item>
      <Form.Item>
          <div className="text-center mt-4">
          <Button
              className="account"
              htmlType="submit"
              type="primary"
              size="large"
              shape="round"
              loading={props.loading}
              block
          >
              パスワードを変更してログイン
          </Button>
          </div>
      </Form.Item>
    </Form>
  );
};

export default FirstPasswordChangeForm;
