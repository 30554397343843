import React, {FC, useContext, useEffect} from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/auth";
import AppLoading from "../../components/common/AppLoading";

// ログイン済みの状態でアクセスした際にTOPにリダイレクトさせる
const GuestGuard: FC = () => {
  const { appUser, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (appUser) {
      navigate("/");
    }
  }, [appUser])

  if (loading) {
    return <AppLoading />;
  }

  return <Outlet />;
};

export default GuestGuard;
