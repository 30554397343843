import { PageHeader, Form, Input, Button, notification, Alert } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { customErrorHandler } from "../../utils/error";
import { useTranslation } from "react-i18next";
import client from '../../core/axios';

// 認証コードをメールに送信 → パスワードリセット
const PasswordForget: React.FC = () => {
  const [confirmForm] = Form.useForm();
  const [submitForm] = Form.useForm();
  const [email, setEmail] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // 認証コード送信
  const onFinishConfirm = async (values: any) => {
    setLoading(true);
    try {
      // https://github.com/aws-amplify/amplify-js/issues/1182#issuecomment-644542465
      // 1. cognitoユーザーのステータスチェック
      const { data: cognitoUser } = await client.get(`/users/cognitoUser?email=${encodeURIComponent(values.email)}`)
      if (!cognitoUser || !cognitoUser.UserStatus) {
        customErrorHandler('登録されていないメールアドレスです');
      }

      if (cognitoUser.UserStatus === 'FORCE_CHANGE_PASSWORD') {
        // 2-A. statusが'FORCE_CHANGE_PASSWORD' (=admin発行後ログイン試行のないユーザー or 期限切れ) の場合はパスワード再発行
        await client.post('/users/requestPassword', {
          email: values.email
        }).catch();
        notification.success({
          message: "パスワード再発行メールを送信しました。",
        });
        navigate('/signin');
      } else if (cognitoUser.UserStatus === 'CONFIRMED') {
        // 2-B. statusが'ENABLED'（=初回ログイン試行済みの場合) パスワード変更メール ➝ 確認コード入力ステップへ
        await Auth.forgotPassword(values.email).catch();
        console.log("Auth", Auth);
        setEmail(values.email);
        setConfirmed(true);
      } else {
        // 2-C. その他ステータス 無効
        customErrorHandler('無効なユーザーアカウントです');
      }
    } catch (err: any) {
      const message = t(`aws.cognito.error.${err.code}`);
      customErrorHandler(message);
    } finally {
      setLoading(false);
    }
  };

  // 認証コード入力 & パスワードリセット
  const onFinishSubmit = async (values: any) => {
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, values.code, values.password);

      notification.success({
        message: "パスワードを変更しました",
      });

      navigate("/signin");
    } catch (err: any) {
      const message = t(`aws.cognito.error.${err.code}`);
      customErrorHandler(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="SignIn">
      <PageHeader title="パスワードの再設定" />
      {confirmed ? (
        <>
          <Alert
            type="info"
            message="メールで送信された認証コードと新しいパスワードを入力してください。"
            className="mb-4"
          />
          <Form
            layout="vertical"
            form={submitForm}
            onFinish={onFinishSubmit}
            className="px-8 md:px-4"
          >
            <Form.Item
              label="認証コード"
              name="code"
              rules={[{ required: true, message: "認証コードは必須です" }]}
            >
              <Input placeholder="認証コード" />
            </Form.Item>
            <Form.Item
              label="パスワード"
              name="password"
              rules={[{ required: true, message: "パスワードは必須です" }]}
            >
              <Input.Password
                placeholder="パスワード(8文字以上・英数字を含む)"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item>
              <div className="text-center mt-4">
                <Button
                  className="account"
                  htmlType="submit"
                  type="primary"
                  size="large"
                  shape="round"
                  loading={loading}
                  block
                >
                  パスワードの再設定
                </Button>
              </div>
            </Form.Item>
          </Form>
        </>
      ) : (
        <Form
          layout="vertical"
          form={confirmForm}
          onFinish={onFinishConfirm}
          className="px-8 md:px-4"
        >
          <Form.Item
            className="mt-4"
            label="メール"
            name="email"
            rules={[
              { required: true, message: "メールアドレスは必須です" },
              {
                type: "email",
                message: "メールアドレスの形式で入力してください",
              },
            ]}
          >
            <Input type="email" placeholder="example@openfirm.com" />
          </Form.Item>
          <Form.Item>
            <div className="text-center mt-4">
              <Button
                className="account"
                htmlType="submit"
                type="primary"
                size="large"
                shape="round"
                loading={loading}
                block
              >
                パスワードを再発行する
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}

      <div className="text-center mt-4">
        <Link to="/signin">ログイン画面へ戻る</Link>
      </div>
    </div>
  );
};

export default PasswordForget;
