import { Button, Form, PageHeader } from "antd";

import React, { useState } from "react";
import { User } from "../../models/user";
import client from "../../core/axios";
import { axiosErrorHandler } from "../../utils/error";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import { useEffect } from "react";
import UserForm from "../../components/user/UserForm";
import _ from "lodash";

const UserCreate: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { appUser, updateAuthResult } = useContext(AuthContext);
  const onFinish = async (values: Partial<User>) => {
    const _values = {...values, tags: _.uniq(values?.tags?.map((data: any)=>{
        return data?.value;
      }) || [])}
    try {
      setLoading(true);
      await client.post("/users", _values).catch(axiosErrorHandler);
      // 認証情報をアップデート
      await updateAuthResult();
      navigate("/");
    } catch (err: any) {
      axiosErrorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  // アプリ側ユーザー作成済みの場合
  useEffect(() => {
    if (appUser) {
      navigate("/");
    }
  }, [appUser, navigate]);

  return (
    <div>
      <PageHeader title="プロフィール登録" />
      <Form layout="vertical" form={form} onFinish={onFinish} className="px-4">
        <UserForm mode="create" form={form} loading={loading} />
      </Form>
    </div>
  );
};
export default UserCreate;
