import React, { useState } from "react";
import {
  Form,
  FormInstance,
  Input,
  Button,

  InputNumber,
  Radio,
} from "antd";
import UploadImage from '../../common/UploadImage';

type KeywordFormProps = {
  mode: "create" | "edit";
  form: FormInstance;
  loading: boolean;
};

const KeywordForm: React.FC<KeywordFormProps> = ({ mode, form, loading }) => {
  return (
    <>
      <Form.Item
        name="title"
        label="タイトル"
        rules={[
          { required: true, message: "この項目は必須です" },
          { max: 60, message: "60文字以内で設定してください" }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="説明"
        rules={[
          { required: true, message: "この項目は必須です" },
        ]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item>
        <div className="text-center mt-4">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            shape="round"
            loading={loading}
          >
            {mode === "create" && "追加する"}
            {mode === "edit" && "更新する"}
          </Button>
        </div>
      </Form.Item>
    </>
  );
};
export default KeywordForm;
