import React, { ReactNode } from "react";
import { css } from "@emotion/css";
import { Spin } from "antd";

const containerStyle = css`
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
`;

const AppLoading: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div className={containerStyle} {...props}>
      <Spin />
    </div>
  );
};
export default AppLoading;
