import React, { ReactNode } from "react";
import { Avatar } from "antd";
import { css } from "@emotion/css";

type Props = {
  title: string;
  icon: ReactNode;
};

const IconStyles = css`
  width: 66px;
  height: 66px;
  border: 1px solid #d8b960;
  border-radius: 25px;
  margin: auto;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleStyles = css`
  margin-top: 0.5rem;
  line-height: 1rem;
  width: 104%;
  margin-left: -2%;
`;

const IconWithTitle: React.FC<Props> = ({ title, icon }) => {
  return (
    <div className="text-center">
      <div className={IconStyles}>{icon}</div>
      <div className={TitleStyles}><span className="leading-5">{title}</span></div>
    </div>
  );
};
export default IconWithTitle;
