import React from "react";
import { css } from "@emotion/css";

const containerStyle = css`
  margin: 20px 0;
  font-size: 1rem;
  text-align: center;
`;

type Props = {
  message: string;
} & React.HTMLAttributes<HTMLDivElement>;

const AppBlankMessage: React.FC<Props> = ({ message, ...rest }) => {
  return (
    <div className={containerStyle} {...rest}>
      {message}
    </div>
  );
};
export default AppBlankMessage;
