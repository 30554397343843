import { ReactComponent as Suffix } from "./files/suffix.svg";
import { ReactComponent as CircleGold } from "./files/icon_circle_gold.svg";
import { ReactComponent as CircleGray } from "./files/icon_circle_gray.svg";
import { ReactComponent as Logo } from "./files/logo.svg";
import { ReactComponent as Present } from "./files/icon_present.svg";
import { ReactComponent as Member } from "./files/icon_member.svg";
import { ReactComponent as Calendar } from "./files/icon_calendar.svg";
import { ReactComponent as Memo } from "./files/icon_memo.svg";
import { ReactComponent as Question } from "./files/icon_question.svg";
import { ReactComponent as Talk } from "./files/icon_talk.svg";
import { ReactComponent as Beta } from "./files/icon_beta.svg";
import { ReactComponent as Bookshelf } from "./files/icon_bookshelf.svg";
import { ReactComponent as Slack } from "./files/icon_slack.svg";
import { ReactComponent as FaceBook } from "./files/icon_fb.svg";
import { ReactComponent as Instagram } from "./files/icon_instagram.svg";
import { ReactComponent as Edit } from "./files/icon_edit.svg";
import { ReactComponent as Plus } from "./files/icon_plus.svg";
import { ReactComponent as Books } from "./files/icon_books.svg";
import { ReactComponent as Microphone } from "./files/icon_microphone.svg";
import { ReactComponent as CJS } from "./files/icon_cjs.svg";

export const Svg = {
  Logo,
  Suffix,
  CircleGold,
  CircleGray,
  Present,
  Member,
  Calendar,
  Memo,
  Question,
  Talk,
  Beta,
  Bookshelf,
  Slack,
  FaceBook,
  Instagram,
  Edit,
  Plus,
  Books,
  Microphone,
  CJS
};
