import React from "react";
import useSWR, { mutate } from "swr";
import { useQueryParam, StringParam } from "use-query-params";
import {
  Alert,
  Modal,
  Button,
  Checkbox,
  PageHeader,
  Form,
  Input,
  Result,
} from "antd";
import { useAxiosClient } from "../../core/axios";
import { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import AppLoading from "../../components/common/AppLoading";
import { ConfigContext } from "../../contexts/config";
import AppContainer from "../../components/common/AppContainer";
import ServiceDetailCard from "../../components/service/ServiceDetailCard";
import useModalCtrl, { ModalCtrl } from "../../hooks/useModalCtrl";
import { Service } from "../../models/service";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useState } from "react";

const OrderCreate: React.FC = () => {
  const [completed, setCompleted] = useState<boolean>(false);
  const [serviceId] = useQueryParam("serviceId", StringParam);
  const { data: service, error } = useSWR(`/services/${serviceId}`);
  const { appUser } = useContext(AuthContext);
  const { adminUser } = useContext(ConfigContext);
  // const [loading, setLoading] = useState(false);
  const { data: originalRomanTotal } = useSWR(`/transactions/total/original`);
  const { data: giftRomanTotal } = useSWR(`/transactions/total/gift`);
  const confirmModalCtl = useModalCtrl();
  const navigate = useNavigate();
  const [comment, setComment] = useState<string>("");

  if (
    service === undefined ||
    adminUser === undefined ||
    originalRomanTotal === undefined ||
    giftRomanTotal === undefined
  ) {
    return <AppLoading />;
  }

  if (completed) {
    return <OrderCreateCompleted service={service} />;
  }

  const onClickSend = () => {
    confirmModalCtl.show();
  };

  const onComplete = () => {
    setCompleted(true);
  };

  const onChangeComment = (evt: any) => {
    setComment(evt.target.value);
  };

  // 運営の場合はgift
  let isShortage = originalRomanTotal < service.price;
  if (service.userId === adminUser?.id) {
    isShortage = giftRomanTotal < service.price;
  }

  return (
    <>
      <div>{(!service || !appUser) && <AppLoading />}</div>
      <PageHeader title="ロマンとサービスを交換する" />
      <AppContainer>
        {service && (
          <>
            <ServiceDetailCard service={service} />
            {isShortage && (
              <Alert
                showIcon
                message="ロマンが足りません"
                type="warning"
                className="mt-4"
              />
            )}
            <div className="mt-4">
              <Form layout="vertical">
                <Form.Item
                  label="コメント"
                  rules={[
                    { max: 200, message: "200文字以内で入力してください" },
                  ]}
                >
                  <Input.TextArea rows={4} onChange={onChangeComment} />
                </Form.Item>
              </Form>
            </div>
            <div className="text-center mt-4">
              <Button
                onClick={onClickSend}
                type="primary"
                size="large"
                shape="round"
                disabled={isShortage}
              >
                ロマンと交換する
              </Button>
            </div>
          </>
        )}
      </AppContainer>
      <OrderConfirmModal
        modalCtl={confirmModalCtl}
        service={service}
        comment={comment}
        onComplete={onComplete}
      />
    </>
  );
};

type OrderCreateCompletedProps = {
  service: Service;
};
const OrderCreateCompleted: React.FC<OrderCreateCompletedProps> = ({
  service,
}) => {
  return (
    <Result
      status="success"
      title={<>ロマンの交換が完了しました。</>}
      extra={
        <div className="text-left">
          <ServiceDetailCard service={service} />
          <div className="mt-4 text-center">
            <Link to="/">
              <Button type="primary" size="large" shape="round">
                TOPへもどる
              </Button>
            </Link>
          </div>
        </div>
      }
    />
  );
};

type OrderConfirmModalProps = {
  service: Service;
  modalCtl: ModalCtrl;
  comment: string;
  onComplete: () => void;
};
const OrderConfirmModal: React.FC<OrderConfirmModalProps> = ({
  modalCtl,
  service,
  comment,
  onComplete,
}) => {
  const { request } = useAxiosClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState(false);

  const onClickSend = async () => {
    setLoading(true);
    await request({
      method: "post",
      url: `/transactions`,
      data: {
        serviceId: service.id,
        comment,
      },
    });
    setTimeout(() => {
      setLoading(false);
      // TODO 整合性担保のため暫定策 最終的にはサーバー側で更新を確認してからレスポンスを返すように制御
      onComplete();
    }, 2000);
  };
  return (
    <Modal visible={modalCtl.visible} footer={null} onCancel={modalCtl.hide}>
      <>
        <div>
          <h3 className="font-bold">ロマン送付時の注意事項</h3>
          <p>
            ・「ロマンと交換する」をクリックした時点で、ロマンの交換が完了します。{" "}
          </p>
          <p>
            ・ロマンの交換が完了しても相手に通知は届きません。<br />{service?.user.name}{" "}さんへ個別にご連絡いただくとスムーズです。{" "}
          </p>
          <p>
            ・ロマンと交換した依頼内容の実施〜完了確認は当事者間でお願いしております。{" "}
          </p>
        </div>
        <ServiceDetailCard service={service} />
        {comment && <div className="mt-4">コメント：「{comment}」</div>}
        <Checkbox
          className="mt-2"
          checked={confirmed}
          onChange={() => {
            setConfirmed(!confirmed);
          }}
        >
          注意事項を確認しました
        </Checkbox>
        <div className="text-center mt-6">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            shape="round"
            loading={loading}
            onClick={onClickSend}
            disabled={!confirmed}
          >
            ロマンと交換する
          </Button>
        </div>
      </>
    </Modal>
  );
};
export default OrderCreate;
