import React from "react";
import { css } from "@emotion/css";
import { FaExternalLinkAlt } from "react-icons/fa";

const footerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  margin: 0 20px;
`;

const Footer: React.FC = () => {
  return (
    <div className={footerStyle}>
      <FaExternalLinkAlt className="mr-2" color="rgb(216, 185, 96)" />
      <a href="https://open-firm.jp" target="_blank">
        OPEN FIRM Official Site
      </a>
    </div>
  );
};
export default Footer;
