import { Avatar, AvatarProps } from "antd";
import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { getUrlByKey } from '../../../utils/storage';

type Props = {
  src?: string | null;
} & AvatarProps;

const UserAvatar: React.FC<Props> = ({ src, ...avatarProps }) => {
  return (
    <>
      <div className="text-center">
        {src ? (
          <Avatar size={64} src={src} {...avatarProps} />
        ) : (
          <Avatar size={64} icon={<UserOutlined />} {...avatarProps} />
        )}
      </div>
    </>
  );
};
export default UserAvatar;
