import { label } from "@aws-amplify/ui";

type DownloadCsvFieldOption = {
  key: string;
  label?: string;
  callback?(row: any): any;
};

export const downloadCsv = (
  dataSource: any[],
  fieldOptions: DownloadCsvFieldOption[]
) => {
  // ヘッダー行作成
  const headerRow: any[] = [];
  fieldOptions.forEach((fieldOption) => {
    if (dataSource.length === 0) {
      return;
    }

    const targetKey = Object.keys(dataSource[0]).find(
      (key) => key === fieldOption.key
    );
    console.log(Object.keys(dataSource[0]), targetKey, fieldOption);

    if (!targetKey) {
      return;
    }
    if (fieldOption?.label) {
      headerRow.push(fieldOption.label);
    } else {
      headerRow.push(targetKey);
    }
  });

  // データ行作成
  const bodyRows = dataSource.map((row: any) =>
    fieldOptions.map((fieldOption) => {
      const targetValue = row[fieldOption.key];
      if (!targetValue) {
        return;
      }

      if (fieldOption?.callback) {
        return fieldOption.callback(row);
      } else {
        return targetValue;
      }
    })
  );

  let csvString = "";
  csvString += headerRow.join(",");
  csvString += "\r\n";
  csvString += bodyRows.map((bodyRow: any) => bodyRow.join(",")).join("\r\n");
  // CSVデータ生成
  const csvData: string = "data:text/csv;charset=utf-8," + csvString;

  // 作成した CSV のダウンロード処理
  const fileName = "data.csv";
  const encodedUri = encodeURI(csvData);
  const ele = document.createElement("a");

  ele.setAttribute("href", encodedUri);
  ele.setAttribute("download", fileName);
  ele.style.visibility = "hidden";
  document.body.appendChild(ele);
  ele.click();
  document.body.removeChild(ele);
};
