import React, { useState } from "react";
import { Form, Modal, Input, InputNumber, Button } from "antd";
import client, { useAxiosClient } from "../../core/axios";
import { axiosErrorHandler } from "../../utils/error";
import { ModalCtrl } from "../../hooks/useModalCtrl";
import { User, Work } from "../../models/user";
import { useEffect } from "react";
import WorkForm from "../../components/user/WorkForm";

type WorkModalProps = {
  user: User;
  index: number;
  work: Work;
  modalCtrl: ModalCtrl;
  onComplete: () => void;
};

type FormType = {
  title: string;
  description: string;
  thumbnailType: 'image' | 'video';
  image?: string;
  video?: string;
};

const WorkEditModal: React.FC<WorkModalProps> = ({
  user,
  index,
  work,
  modalCtrl,
  onComplete,
}) => {
  const title = "Worksを編集する";
  const [loading, setLoading] = useState(false);
  const { visible, hide } = modalCtrl;
  const [form] = Form.useForm();

  const onFinish = async (params: FormType) => {
    const { createdAt, updatedAt, ...userParams } = user;
    const newWorks = [...user.works || []];
    newWorks[index] = params;
    setLoading(true);
    const res = await client
      .patch(`/users/${user.id}`, {
        ...userParams,
        works: newWorks
      })
      .catch(axiosErrorHandler);
    
    setLoading(false);
    if (res) {
      hide();
      onComplete();
    }
  };

  const onClickDelete = async () => {
    const { createdAt, updatedAt, ...userParams } = user;
    const newWorks = [...user.works || []];
    newWorks.splice(index, 1);
    const res = await client
      .patch(`/users/${user.id}`, {
        ...userParams,
        works: newWorks
      })
      .catch(axiosErrorHandler);

    if (res) {
      hide();
      onComplete();
    }
  };

  return (
    <Modal title={title} visible={visible} footer={null} onCancel={hide}>
      <Form<FormType> onFinish={onFinish} initialValues={work} form={form}>
        <WorkForm mode="edit" form={form} loading={loading} />
        <div className="text-center">
          <Button className="mt-4" type="link" onClick={onClickDelete}>削除する</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default WorkEditModal;
