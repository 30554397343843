import { Button, Modal } from "antd";
import { useAxiosClient } from "../../core/axios";
import { Service } from "../../models/service";
import { ModalCtrl } from "../../hooks/useModalCtrl";

type ServiceDeleteModalProps = {
  service: Service;
  modalCtl: ModalCtrl;
  onComplete: () => void;
};
const ServiceDeleteModal: React.FC<ServiceDeleteModalProps> = ({
  service,
  modalCtl,
  onComplete,
}) => {
  const { request, loading } = useAxiosClient();
  const onClickDelete = async () => {
    await request({
      method: "delete",
      url: `/services/${service.id}`,
    });
    // await client.delete(`/services/${service.id}`);
    onComplete();
  };

  return (
    <Modal
      visible={modalCtl.visible}
      footer={null}
      onCancel={() => {
        modalCtl.hide();
      }}
    >
      <div className="my-4">削除しますか？</div>
      <div className="text-center">
        <Button
          type="primary"
          size="large"
          shape="round"
          onClick={onClickDelete}
          loading={loading}
        >
          削除する
        </Button>
      </div>
    </Modal>
  );
};

export default ServiceDeleteModal