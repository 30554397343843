import React from "react";
type Props = {
  json: any;
};
const AppJsonDump: React.FC<Props> = (props) => {
  return (
    <pre
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(props.json, null, 2).replace(/\n/g, "<br />"),
      }}
    ></pre>
  );
};

export default AppJsonDump;
