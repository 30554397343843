import React, { useMemo } from "react";
import {
  Form,
  FormInstance,
  Input,
  Button,
  InputNumber,
} from "antd";
import client from "../../../core/axios";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/auth";
import { Rule } from 'antd/lib/form';

type ServiceFormProps = {
  mode: "create" | "edit";
  form: FormInstance;
  loading: boolean;
};

const ServiceForm: React.FC<ServiceFormProps> = ({ mode, form, loading }) => {
  const { appUser } = useContext(AuthContext);

  const priceValidationRules = useMemo<Rule[]>(() => {
    let rules: Rule[] = [
      { required: true, message: "ロマンは必須です" },
      {
        type: "number",
        min: 1,
        message: "最低1ロマン以上を設定してください",
      },
    ];
    if (appUser && appUser.type === "admin") {
      // type: admin（運営）の場合は上限なしにする
    } else {
      rules.push({
        type: "number",
        max: 100,
        message: "最大100ロマンまで設定できます",
      });
    }
    return rules;
  }, [appUser]);

  console.log(priceValidationRules);

  return (
    <>
      <Form.Item
        name="title"
        label="タイトル"
        rules={[{ max: 60, message: "60文字以内で設定してください" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="説明文"
        rules={[{ max: 140, message: "140文字以内で設定してください" }]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item
        name="price"
        label="ロマン"
        rules={priceValidationRules}
      >
        <InputNumber step={1} />
      </Form.Item>
      <Form.Item>
        <div className="text-center mt-4">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            shape="round"
            loading={loading}
          >
            {mode === "create" && "追加する"}
            {mode === "edit" && "更新する"}
          </Button>
        </div>
      </Form.Item>
    </>
  );
};
export default ServiceForm;
