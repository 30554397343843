import { Button, Descriptions, notification, Popconfirm, Table } from "antd";
import React, { useContext } from "react";
import useSWR, { mutate } from "swr";
import AppContainer from "../../components/common/AppContainer";
import client from "../../core/axios";
import { User } from "../../models/user";
const { Column } = Table;

const UserList: React.FC = () => {
  const usersPath = `/users?type=normal`;
  const usersQuery = useSWR(usersPath);

  const onClickDelete = async (user: User) => {
    const userRemovePath = `/users/${user.id}`;
    await client.delete(userRemovePath);
    notification.success({
      message: `ユーザー: ${user.name}を削除しました。`,
    });
    mutate(usersPath);
  };

  return (
    <div>
      <AppContainer>
        <Table
          dataSource={usersQuery.data}
          loading={usersQuery.data === undefined}
        >
          <Column title="氏名" dataIndex="name" />
          <Column title="会員ID" dataIndex="serialNumber" />
          <Column title="メール" dataIndex="email" />
          <Column
            render={(user) => {
              return (
                <>
                  <Popconfirm
                    placement="topLeft"
                    title="このユーザーを本当に削除しますか？"
                    onConfirm={() => {
                      onClickDelete(user);
                    }}
                    okText="OK"
                    cancelText="キャンセル"
                  >
                    <Button type="link">削除</Button>
                  </Popconfirm>
                </>
              );
            }}
          ></Column>
        </Table>
      </AppContainer>
    </div>
  );
};

export default UserList;
