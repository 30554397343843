import React, { useEffect } from "react";
import { Row, Col, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import useSWR, { mutate } from "swr";
import IconWithTitle from "./IconWithTitle";
import { useContext } from "react";
import { ConfigContext } from "../../contexts/config";
import AppContainer from "../../components/common/AppContainer";
import AppPageInnerHeader from "../../components/common/AppPageInnerHeader";
import { Svg } from "../../components/common/Svg";
import TransactionList from "../../components/transaction/TransactionList";
import TotalAmountCard from "../../components/roman/TotalAmountCard";
import AppLoading from "../../components/common/AppLoading";
import { css } from "@emotion/css";
import {
  FaMicrophone,
  FaBuilding,
  FaCalendarAlt,
  FaFacebook,
  FaQuestion,
  FaSlack,
  FaUserFriends,
} from "react-icons/fa";

import { BsFillCartFill } from "react-icons/bs";
import { HiLightBulb } from "react-icons/hi";
import { CgSandClock } from "react-icons/cg";

const {
  CircleGold,
  CircleGray,
  Member,
  Present,
  Beta,
  Microphone,
  Books,
  CJS
} = Svg;

const LinkHoverStyle = css`
  &:hover {
    svg, path, g {
      fill: #e6d28a !important;
    }
  }
`

const Home: React.FC = () => {
  const originalRoman = useSWR(`/transactions/total/original`);
  const giftRoman = useSWR(`/transactions/total/gift`);
  const { adminUserPath } = useContext(ConfigContext);
  const navigate = useNavigate();

  if (!adminUserPath || originalRoman.isValidating || giftRoman.isValidating) {
    return <AppLoading />;
  }

  const contentBgWhiteStyles = css`
    background-color: #fff;
    margin-top: 24px;
    padding: 24px;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
  `;

  const historyListBlockStyles = css`
    background-color: #f7f5f5;
    border-radius: 16px;
    padding: 24px;
    max-width: 700px;
    margin: auto;
  `;

  return (
    <AppContainer>
      <TotalAmountCard
        cardTitle={
          <span style={{ color: "#D8B960" }} className="flex items-center">
            <CircleGold className="mr-2" width="20px" height="20px" />
            ちょっとお願いロマン
          </span>
        }
        amount={originalRoman.data}
      >
        <div className="text-center mt-2">
          <Link to="/users">
            <Button
              className="flex items-center justify-center m-auto"
              type="primary"
              size="large"
              shape="round"
              block
            >
              <Member className="mr-2" width="22px" height="22px" />
              メンバーに仕事を依頼する
            </Button>
          </Link>
        </div>
      </TotalAmountCard>
      <TotalAmountCard
        cardTitle={
          <span style={{ color: "#B2B2B2" }} className="flex items-center">
            <CircleGray className="mr-2" width="20px" height="20px" />
            Thank youロマン
          </span>
        }
        amount={giftRoman.data}
        className="mt-4"
      >
        <div className="text-center mt-2">
          <Link to={adminUserPath}>
            <Button
              className="flex items-center justify-center m-auto"
              type="primary"
              size="large"
              shape="round"
              block
            >
              <Present className="mr-2" width="22px" height="22px" />
              物資と交換する
            </Button>
          </Link>
        </div>
      </TotalAmountCard>
      <Row gutter={[12, 12]} className="mt-6">
        <Col xs={6}>
          <Link to="/users" className={LinkHoverStyle}>
            <IconWithTitle
              title="MEMBER"
              icon={<FaUserFriends size="24px" />}
            />
          </Link>
        </Col>
        <Col xs={6}>
          <a href="https://www.hatch-888.jp/openfirm-member" target="_blank" className={LinkHoverStyle}>
            <IconWithTitle
              title="OPEN MIC"
              icon={<Microphone width="24px" />}
            />
          </a>
        </Col>
        <Col xs={6}>
          <a href="https://www.notion.so/open-firm/OPEN-MIC-ARCHIVES-7a98803f1627424db0c38d31d4df4963" target="_blank" className={LinkHoverStyle}>
            <IconWithTitle
              title="CONTENTS ARCHIVES"
              icon={<Books width="24px" />}
            />
          </a>
        </Col>
        <Col xs={6}>
          <a href="javascript:alert('COMING SOON')" className={LinkHoverStyle}>
            <IconWithTitle
              title="COMING SOON"
              icon={<CgSandClock size="24px" />}
            />
          </a>
        </Col>
        <Col xs={6}>
          <a href="https://www.notion.so/open-firm/CJS-Creative-Jam-Session-3fffbdbfa3a24471bca4b6c257a9fcfd" target="_blank" className={LinkHoverStyle}>
            <IconWithTitle title="CREATIVE JAM SESSION" icon={<CJS width={"36px"} />} />
          </a>
        </Col>
        <Col xs={6}>
          <a href="https://www.notion.so/open-firm/62675c22bdba4b138a0ddf4a4bd2b5d0" target="_blank" className={LinkHoverStyle}>
            <IconWithTitle title="β" icon={<Beta width="24px" />} />
          </a>
        </Col>
        <Col xs={6}>
          <a href="https://www.notion.so/open-firm/CALENDER-0f3d7471cf1c46e1b1268b4740fbb1fa" target="_blank" className={LinkHoverStyle}>
            <IconWithTitle
              title="CALENDER"
              icon={<FaCalendarAlt size="24px" />}
            />
          </a>
        </Col>
        <Col xs={6}>
          <a href="javascript:alert('COMING SOON')" className={LinkHoverStyle}>
            <IconWithTitle title="STORE" icon={<BsFillCartFill size="24px" />} />
          </a>
        </Col>
        <Col xs={6}>
          {/* <a href="https://hatch-virtualopenfirm.ovice.in/@403,810" target="_blank" className={LinkHoverStyle}>
            <IconWithTitle
              title="VIRTUAL OFFICE"
              icon={<FaBuilding size="24px" />}
            />
          </a> */}
          <a href="javascript:alert('COMING SOON')" className={LinkHoverStyle}>
            <IconWithTitle
              title="VIRTUAL OFFICE"
              icon={<FaBuilding size="24px" />}
            />
          </a>
        </Col>
        <Col xs={6}>
          <a href="https://www.facebook.com/groups/openfirm" target="_blank" className={LinkHoverStyle}>
            <IconWithTitle
              title="COMMUNITY"
              icon={<FaFacebook size={"28px"} />}
            />
          </a>
        </Col>
        <Col xs={6}>
          <a href="https://open-firm.slack.com/" target="_blank" className={LinkHoverStyle}>
            <IconWithTitle title="SLACK" icon={<FaSlack size={"28px"} />} />
          </a>
        </Col>
        <Col xs={6}>
          <a href="https://www.notion.so/open-firm/FAQ-84f73ed761ad463ab1bdf9d1b9b75419" target="_blank" className={LinkHoverStyle}>
            <IconWithTitle title="FAQ" icon={<FaQuestion size="24px" />} />
          </a>
        </Col>
      </Row>
      <div className={contentBgWhiteStyles}>
        <AppPageInnerHeader title="最近のロマン取引履歴" />
        <div className={historyListBlockStyles}>
          <TransactionList limit={5} showDetail={false} />
          <div className="text-center">
            <Button
              htmlType="button"
              type="primary"
              size="large"
              shape="round"
              onClick={() => {
                navigate("/transactions");
              }}
            >
              もっと見る
            </Button>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};
export default Home;
