import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { css } from "@emotion/css";
const { Content } = Layout;

const wrapperStyle = css`
  min-height: 100vh;
  background-color: #f7f5f5;
`;

// SPファーストレイアウトでPCはmax-width750pxで固定
const contentStyle = css`
  max-width: 750px;
  margin: auto;
`;

const AppLayout: React.FC = () => {
  return (
    <div className={wrapperStyle}>
      <Header />
      <Content className={contentStyle}>
        <Outlet />
      </Content>
      <Footer />
    </div>
  );
};
export default AppLayout;
