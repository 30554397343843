import { PageHeader } from "antd";
import React from "react";
import AppContainer from "../../components/common/AppContainer";
import AppListWrapper from "../../components/common/AppListWrapper";
import TransactionList from "../../components/transaction/TransactionList";

const TransactionListPage: React.FC = () => {
  return (
    <div>
      <PageHeader title="ロマン取引履歴" />
      <AppContainer>
        <AppListWrapper>
          <TransactionList showDetail={true} />
        </AppListWrapper>
      </AppContainer>
    </div>
  );
};
export default TransactionListPage;
