import { AxiosError, AxiosProxyConfig } from "axios";
import { notification } from "antd";

const axiosErrorHandler = (err: AxiosError) => {
  notification.error({
    message: err.message,
  });
};

const customErrorHandler = (message: string) => {
  notification.error({
    message: message,
  });
};

export { axiosErrorHandler, customErrorHandler };
