import React, { useState } from "react";
import { Form, Modal } from "antd";
import client from "../../core/axios";
import { axiosErrorHandler } from "../../utils/error";
import { ModalCtrl } from "../../hooks/useModalCtrl";
import ServiceForm from "../../components/service/ServiceForm";

type ServiceModalProps = {
  modalCtrl: ModalCtrl;
  onComplete: () => void;
};

type FormType = {
  title: string;
  price: number;
};

const initialValues = {
  title: "",
  price: null,
};

const ServiceModal: React.FC<ServiceModalProps> = ({
  modalCtrl,
  onComplete,
}) => {
  const title = "サービスを追加する";
  const [loading, setLoading] = useState(false);
  const { visible, hide } = modalCtrl;
  const [form] = Form.useForm();

  const onFinish = async (params: FormType) => {
    setLoading(true);
    const res = await client.post("/services", params).catch(axiosErrorHandler);
    setLoading(false);
    if (res) {
      form.resetFields();
      hide();
      onComplete();
    }
  };

  return (
    <Modal title={title} visible={visible} footer={null} onCancel={hide}>
      <Form<FormType>
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <ServiceForm mode="create" form={form} loading={loading} />
      </Form>
    </Modal>
  );
};

export default ServiceModal;
