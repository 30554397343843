import React, { useState } from "react";
import { Form, Modal, Input, InputNumber, Button } from "antd";
import client from "../../core/axios";
import { axiosErrorHandler } from "../../utils/error";
import { ModalCtrl } from "../../hooks/useModalCtrl";
import { Service } from "../../models/service";
import { useEffect } from "react";
import ServiceForm from "../../components/service/ServiceForm";

type ServiceModalProps = {
  service: Service;
  modalCtrl: ModalCtrl;
  onComplete: () => void;
};

type FormType = {
  title: string;
  price: number;
};

const ServiceEditModal: React.FC<ServiceModalProps> = ({
  service,
  modalCtrl,
  onComplete,
}) => {
  const title = "サービスを編集する";
  const [loading, setLoading] = useState(false);
  const { visible, hide } = modalCtrl;
  const [form] = Form.useForm();

  const onFinish = async (params: FormType) => {
    setLoading(true);
    const res = await client
      .patch(`/services/${service.id}`, params)
      .catch(axiosErrorHandler);
    setLoading(false);
    if (res) {
      hide();
      onComplete();
    }
  };

  return (
    <Modal title={title} visible={visible} footer={null} onCancel={hide}>
      <Form<FormType> onFinish={onFinish} initialValues={service} form={form}>
        <ServiceForm mode="edit" form={form} loading={loading} />
      </Form>
    </Modal>
  );
};

export default ServiceEditModal;
